import { Plus } from '@air/next-icons';
import { tailwindMerge } from '@air/tailwind-variants';
import { memo } from 'react';

import {
  PersonSelectItemBase,
  PersonSelectItemBaseProps,
} from '~/components/AssetModal/shared/components/FacesPanel/components/PersonTextSelect/components/PersonSelectItemBase';

type CreateNewPersonSelectItemProps = Omit<PersonSelectItemBaseProps, 'prefix'>;

export const CreateNewPersonSelectItem = memo(({ className, ...props }: CreateNewPersonSelectItemProps) => (
  <PersonSelectItemBase
    data-testid="CREATE_NEW_PERSON"
    className={tailwindMerge('font-semibold text-grey-10', className)}
    prefix={<Plus className="size-4" />}
    {...props}
  />
));

CreateNewPersonSelectItem.displayName = 'CreateNewPersonSelectItem';
