import { Clip } from '@air/api/types';
import { EllipsisVertical, Pencil } from '@air/next-icons';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { hasSelectedMultipleItemsSelector, itemIsHighlightedOnShiftSelectSelector } from '@air/redux-selected-items';
import { memo, MouseEvent, useCallback, useMemo, useState } from 'react';

import { type AssetItemProps } from '~/components/Gallery/types';
import { AssetCellThumbnail } from '~/components/TableView/NameCell/AssetCellThumbnail';
import { NameCellContent } from '~/components/TableView/NameCell/NameCellContent';
import { PrivateAssetNameCellTitle } from '~/components/TableView/PrivateTableView/PrivateAssetNameCellTitle';
import { useClipPermissions } from '~/hooks/useClipPermissions';
import { canChangeVersionDetails } from '~/utils/permissions/assetPermissions';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface PrivateAssetNameCellProps extends AssetItemProps {
  isHovering: boolean;
  isSelected: boolean;
  clip: Clip;
}

export const PrivateAssetNameCell = memo(
  ({
    isHovering,
    isSelected,
    clip,
    getItemMenuOptions,
    getSelectionMenuOptions,
    onCommentsClick,
    onItemClick,
    onItemCmdClick,
    onVersionsClick,
  }: PrivateAssetNameCellProps) => {
    const itemTitle = clip.title || clip.importedName || 'Untitled';
    const { clipPermissions } = useClipPermissions({
      clipId: clip.id,
    });
    const canEditName = canChangeVersionDetails(clipPermissions);

    const [isRenaming, setIsRenaming] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const isHighlightedFromShiftSelect = useAirSelector((st) => itemIsHighlightedOnShiftSelectSelector(st, clip.id));
    const hasSelectedMultipleItems = useAirSelector(hasSelectedMultipleItemsSelector);

    const shouldShowMenu = isRenaming || (!isHighlightedFromShiftSelect && (isHovering || menuIsOpen));
    const shouldShowRenameButton =
      canEditName && !hasSelectedMultipleItems && !isHighlightedFromShiftSelect && isHovering && !isRenaming;

    const handleClickRenameButton = useCallback((event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsRenaming(true);
    }, []);

    const _title = useMemo(() => {
      return (
        <PrivateAssetNameCellTitle
          id={clip.id}
          assetId={clip.assetId}
          isRenaming={isRenaming}
          title={itemTitle}
          onClick={onItemClick}
          onCmdClick={onItemCmdClick}
          onCommentsClick={onCommentsClick}
          onVersionsClick={onVersionsClick}
          openCommentCount={clip.openCommentCount || 0}
          assetVersionCount={clip.assetVersionCount || 0}
          pagesCount={clip.pages || 0}
          setIsRenaming={setIsRenaming}
        />
      );
    }, [
      clip.pages,
      clip.assetId,
      clip.assetVersionCount,
      clip.id,
      clip.openCommentCount,
      isRenaming,
      itemTitle,
      onCommentsClick,
      onItemClick,
      onItemCmdClick,
      onVersionsClick,
    ]);

    const _image = useMemo(() => {
      return <AssetCellThumbnail {...clip} description={itemTitle} />;
    }, [clip, itemTitle]);

    const dataTestId = `ASSET_NAME_CELL`;

    const ellipsisMenuOptions = useMemo(
      () => getItemMenuOptions({ trackLocation: 'table-item-ellipsis-menu' }),
      [getItemMenuOptions],
    );

    const selectionMenuOptions = getSelectionMenuOptions('table-multi-select-ellipsis-menu');
    const menuOptions = isSelected && selectionMenuOptions.length ? selectionMenuOptions : ellipsisMenuOptions;

    return (
      <NameCellContent data-testid={dataTestId} title={_title} image={_image}>
        <div className="flex items-center gap-1">
          {shouldShowRenameButton && (
            <IconButton
              appearance="ghost"
              color="grey"
              data-testid={`${dataTestId}-rename`}
              icon={Pencil}
              onClick={handleClickRenameButton}
              onMouseDown={handleClickRenameButton}
              onDoubleClick={(event) => event.stopPropagation()}
              label="Rename"
              size="medium"
            />
          )}
          {shouldShowMenu && !!menuOptions.length && (
            <DropdownMenu
              onOpenChange={setMenuIsOpen}
              trigger={
                <IconButton
                  appearance="ghost"
                  color="grey"
                  data-testid={`${dataTestId}-menu`}
                  icon={EllipsisVertical}
                  onClick={(event) => event.stopPropagation()}
                  onDoubleClick={(event) => event.stopPropagation()}
                  label="More options"
                  size="medium"
                />
              }
            >
              {renderDropdownItems({ options: menuOptions })}
            </DropdownMenu>
          )}
        </div>
      </NameCellContent>
    );
  },
);

PrivateAssetNameCell.displayName = 'PrivateAssetNameCell';
