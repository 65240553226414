import classNames from 'classnames';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

export type VideoSeekbarPreviewProps = Pick<ComponentPropsWithoutRef<'div'>, 'children' | 'className'>;

export const VideoSeekbarPreview = forwardRef<HTMLDivElement, VideoSeekbarPreviewProps>(
  ({ children, className }, forwardedRef) => {
    return (
      <div
        className={classNames(
          'pointer-events-none absolute bottom-full z-[4] hidden -translate-x-1/2 -translate-y-10 overflow-hidden rounded bg-black shadow-popover ring-2 ring-white/80',
          'before:absolute before:bottom-2 before:left-1/2 before:flex before:h-6 before:-translate-x-1/2 before:items-center before:rounded before:bg-black/60 before:px-2 before:text-center before:text-12 before:font-medium before:text-white before:backdrop-blur before:content-[attr(data-timestamp)] before:font-feature-tnum',
          className,
        )}
        ref={forwardedRef}
      >
        {children}
      </div>
    );
  },
);

VideoSeekbarPreview.displayName = 'VideoSeekbarPreview';
