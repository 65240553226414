import { Check as CheckIcon } from '@air/next-icons';
import { ComponentPropsWithoutRef, memo } from 'react';

import { PersonSelectItemBase } from '~/components/AssetModal/shared/components/FacesPanel/components/PersonTextSelect/components/PersonSelectItemBase';
import { FacePersonThumbnail } from '~/components/FacePersonThumbnail/FacePersonThumbnail';
import { ClipFaceThumbnail } from '~/swr-hooks/clips/faces/types';

export interface PersonSelectItemType {
  value: string;
  label: string;
  thumbnail?: ClipFaceThumbnail;
}

export interface PersonSelectItemProps extends Omit<ComponentPropsWithoutRef<'li'>, 'prefix'> {
  person: PersonSelectItemType;
  isHighlighted: boolean;
  isSelected: boolean;
}

export const PersonSelectItem = memo(({ person, isSelected, ...props }: PersonSelectItemProps) => {
  const thumbnail = person.thumbnail;

  return (
    <PersonSelectItemBase
      data-testid="PERSON_SELECT_ITEM"
      data-person-id={person.value}
      label={person.label}
      prefix={
        !!thumbnail ? (
          <FacePersonThumbnail shape="circle" faceThumbnail={thumbnail} height={20} width={20} />
        ) : undefined
      }
      suffix={isSelected && <CheckIcon className="size-4 shrink-0 text-blue-10" />}
      {...props}
    />
  );
});

PersonSelectItem.displayName = 'PersonSelectItem';
