import { Workspaces } from '@air/api';
import { ViewTypeName } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { getConfigViewOptionsKey } from '~/constants/react-query-keys';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { filterOutIPTCFields } from '~/swr-hooks/gallery/viewFields/filterOutIPTCFields';
import { useCanSeePrivateIPTCFields } from '~/swr-hooks/workspaces/addOns/useCanSeePrivateIPTCFields';

export const useGetPrivateConfigViewOptions = ({ viewType }: { viewType: ViewTypeName | undefined }) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const { canSeePrivateIPTCFields } = useCanSeePrivateIPTCFields();

  return useQuery({
    queryKey: getConfigViewOptionsKey({
      workspaceId: currentWorkspace?.id,
      viewType,
      canSeeIPTCFields: canSeePrivateIPTCFields,
    }),

    queryFn: async () => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      if (!viewType) {
        throw new Error('No view type');
      }

      const response = await Workspaces.listViewFields({ name: viewType, workspaceId });
      return canSeePrivateIPTCFields ? response : filterOutIPTCFields(response);
    },
    enabled: !!viewType && !!workspaceId,
  });
};
