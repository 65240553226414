import { isError } from 'lodash';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { EditableContentHeaderTitle } from '~/components/PageHeader/EditableContentHeaderTitle';
import { BOARD_BLURB_TITLE } from '~/constants/testIDs';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { centralizedBoardSelector, centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { isPrivateBoard } from '~/store/centralizedBoard/utils';
import { BoardTitleValidation } from '~/swr-hooks/boards/useCreateNewBoard';
import { useUpdateBoard } from '~/swr-hooks/boards/useUpdateBoard';
import { canChangeBoardDetails } from '~/utils/permissions/boardPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

const BoardTitleValidationScheme = Yup.object().shape({
  ['title']: BoardTitleValidation,
});

const validateBoardTitle = (value: string) => {
  try {
    BoardTitleValidationScheme.validateSync({ title: value });
  } catch (error) {
    return isError(error) ? error.message : 'Invalid title';
  }
};

export const PrivateBoardHeaderTitle = memo(() => {
  const store = useAirStore();
  const title = useSelector(centralizedBoardTitleSelector);
  const { updateBoard } = useUpdateBoard();
  const { boardPermissions } = useCurrentBoardPermissions();

  const onSubmit = useCallback(
    (title: string) => {
      const board = centralizedBoardSelector(store.getState());
      if (board && isPrivateBoard(board)) {
        updateBoard.mutate({ board, values: { title }, trackLocation: 'header' });
      }
    },
    [store, updateBoard],
  );

  if (!title) return null;

  return (
    <EditableContentHeaderTitle
      maxLength={65}
      label="Board name"
      placeholder="Enter a name"
      data-testid={BOARD_BLURB_TITLE}
      defaultValue={title}
      validate={validateBoardTitle}
      onSubmit={canChangeBoardDetails(boardPermissions) ? onSubmit : undefined}
    />
  );
});

PrivateBoardHeaderTitle.displayName = 'PrivateBoardHeaderTitle';
