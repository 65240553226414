import { AlertModalCloseButton, AlertModalProps, AlertModalTitle } from '@air/primitive-alert-modal';
import pluralize from 'pluralize';

import { DeletePrivateClipsResult } from '~/swr-hooks/clips/useDeletePrivateClips';

type DeleteAssetMessageModalTitleProps = Pick<DeletePrivateClipsResult, 'ignoredAssetIds' | 'removedAssetIds'> &
  Pick<AlertModalProps, 'onDismiss'>;

export const DeleteAssetMessageModalTitle = ({
  onDismiss,
  ignoredAssetIds,
  removedAssetIds,
}: DeleteAssetMessageModalTitleProps) => {
  return (
    <div className="mb-3 flex justify-between">
      <AlertModalTitle data-testid="DELETE_ASSET_MESSAGE_MODAL_TITLE">
        {ignoredAssetIds.length
          ? `Unable to delete ${pluralize('asset', ignoredAssetIds.length, ignoredAssetIds.length > 1)}`
          : removedAssetIds.length === 1
          ? 'Asset was removed from board(s) instead'
          : `${removedAssetIds.length} assets were removed from board(s) instead`}
      </AlertModalTitle>
      <AlertModalCloseButton data-testid="DELETE_ASSET_MESSAGE_MODAL_CLOSE" className="shrink-0" onClick={onDismiss} />
    </div>
  );
};
