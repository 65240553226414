import { AirActionTrackingLocation } from '@air/analytics';
import { Search as SearchIcon } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { useBreakpointsContext } from '@air/provider-media-query';
import classNames from 'classnames';
import { ComponentType, memo, ReactNode } from 'react';

import { MobilePageHeader } from '~/components/Layouts/HomeLayout/HomeLayoutHeader/MobilePageHeader';
import { BasePrivateSearchProps } from '~/components/Search/PrivateSearch';
import { SearchRow } from '~/components/Shared/HeaderBar/SearchRow';
import { useMobileSearchBar } from '~/hooks/search/useMobileSearchBar';

export interface PrivateHeaderSearchProps {
  title?: string;
  searchPlaceholder?: string;
  trackSearchBarLocation: AirActionTrackingLocation | string;
  searchRowLeftAdornment?: ReactNode;
  searchRowRightAdornment?: ReactNode;
  searchBoxClassName?: string;
  filtersButton?: ReactNode;
  Search: ComponentType<BasePrivateSearchProps>;
}

export const PrivateHeaderSearchRow = memo(
  ({
    title,
    searchPlaceholder,
    trackSearchBarLocation,
    searchBoxClassName,
    searchRowLeftAdornment,
    searchRowRightAdornment,
    filtersButton,
    Search,
  }: PrivateHeaderSearchProps) => {
    const { isAboveMediumScreen } = useBreakpointsContext();
    const { openMobileSearch, isMobileSearchOpen, closeMobileSearch } = useMobileSearchBar();

    return (
      <SearchRow className="searchRow">
        {!isMobileSearchOpen && !isAboveMediumScreen && <MobilePageHeader title={title} isCentered={false} />}
        <div className={classNames('flex grow items-center justify-end md:justify-start', searchBoxClassName)}>
          {searchRowLeftAdornment}
          {isAboveMediumScreen || isMobileSearchOpen ? (
            <Search
              placeholder={searchPlaceholder}
              onCloseClick={closeMobileSearch}
              trackLocation={trackSearchBarLocation}
            />
          ) : (
            <IconButton size="large" onClick={openMobileSearch} appearance="ghost" color="grey" label="Search">
              <SearchIcon className="size-6" />
            </IconButton>
          )}
          <div className="ml-2">{filtersButton}</div>
        </div>
        {searchRowRightAdornment}
      </SearchRow>
    );
  },
);

PrivateHeaderSearchRow.displayName = 'PrivateHeaderSearch';
