import { ComponentPropsWithoutRef, memo, ReactNode } from 'react';

import { PersonSelectItemContainer } from '~/components/AssetModal/shared/components/FacesPanel/components/PersonTextSelect/components/PersonSelectItemContainer';

export interface PersonSelectItemBaseProps extends Omit<ComponentPropsWithoutRef<'li'>, 'prefix'> {
  label: string;
  isHighlighted: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
}

export const PersonSelectItemBase = memo(
  ({ label, isHighlighted, className, prefix, suffix, ...props }: PersonSelectItemBaseProps) => {
    return (
      <PersonSelectItemContainer isHighlighted={isHighlighted} className={className} {...props}>
        <div className="flex flex-1 items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="shrink-0">{prefix}</div>
            <span className="select-none truncate">{label}</span>
          </div>
          {suffix}
        </div>
      </PersonSelectItemContainer>
    );
  },
);

PersonSelectItemBase.displayName = 'PersonSelectItemBase';
