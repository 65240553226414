import { useSwitchWorkspace } from 'core/src/hooks/useSwitchWorkspace';
import { useCallback, useRef } from 'react';

import { useSysadmin } from './SysadminProvider';

export const useSwitchSysadminWorkspace = () => {
  const { switchWorkspace } = useSwitchWorkspace();

  const { isInternal, workspaceId: adminWorkspaceId, handleSetWorkspaceId: setSysAdminWorkspaceId } = useSysadmin();
  const adminWorkspaceRef = useRef(adminWorkspaceId);
  adminWorkspaceRef.current = adminWorkspaceId;

  const switchSysadminWorkspace = useCallback(
    ({ workspaceId }: { workspaceId: string }) => {
      if (isInternal) {
        // We do not want to subscribe on adminWorkspaceId change, because that would cause a revert of workspace id
        // if user wants to switch the workspace while viewing a board
        // That's why we use ref instead of adminWorkspaceId directly
        const currentWorkspaceId = adminWorkspaceRef.current;
        if (!!currentWorkspaceId && currentWorkspaceId !== workspaceId) {
          switchWorkspace({ id: workspaceId });
          setSysAdminWorkspaceId(workspaceId);
        }
      }
    },
    [isInternal, setSysAdminWorkspaceId, switchWorkspace],
  );

  return {
    isInternal,
    switchSysadminWorkspace,
  };
};
