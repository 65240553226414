import { AlertModal } from '@air/primitive-alert-modal';
import { Button } from '@air/primitive-button';
import { preSelectItemsAction, selectableItemsSelector } from '@air/redux-selected-items';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DeletePrivateClipsResult } from '~/swr-hooks/clips/useDeletePrivateClips';

import { DeleteAssetMessageModalDescription } from './DeleteAssetMessageModalDescription';
import { DeleteAssetMessageModalTitle } from './DeleteAssetMessageModalTitle';

export type DeleteAssetMessageModalProps = AirModalProps<DeletePrivateClipsResult>;

export const DeleteAssetMessageModal = ({ onClose, children, ...restProps }: DeleteAssetMessageModalProps) => {
  const dispatch = useDispatch();
  const selectableItems = useSelector(selectableItemsSelector);
  const leastDestructiveRef = useRef(null);

  const onDismiss = () => {
    // Pre-select the items that were ignored
    const selectedAssetIdClipIdMap = selectableItems.reduce<Record<string, string>>((acc, selectableItem) => {
      acc[selectableItem.item.assetId] = selectableItem.id;
      return acc;
    }, {});
    dispatch(
      preSelectItemsAction({
        itemsIds: restProps.ignoredAssetIds.map((assetId) => selectedAssetIdClipIdMap[assetId]).filter(Boolean),
      }),
    );
    onClose();
  };

  return (
    <AlertModal
      data-testid="DELETE_ASSET_MESSAGE_MODAL"
      isOpen={restProps.removedAssetIds.length > 0 || restProps.ignoredAssetIds.length > 0}
      onDismiss={onDismiss}
      leastDestructiveRef={leastDestructiveRef}
    >
      <DeleteAssetMessageModalTitle {...restProps} onDismiss={onDismiss} />
      <DeleteAssetMessageModalDescription {...restProps} />

      {children}

      <div className="-mx-6 -mb-6 flex justify-end gap-3 px-6 py-4">
        <Button
          appearance="filled"
          color={'blue'}
          data-testid="DELETE_ASSET_MESSAGE_MODAL_CTA"
          onClick={onDismiss}
          size="large"
          ref={leastDestructiveRef}
        >
          Dismiss
        </Button>
      </div>
    </AlertModal>
  );
};
