'use client';

import { ShortUrlObjectType } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo } from 'react';

import { PrivateBoardAddButton } from '~/components/PrivateBoard/PrivateBoardAddButton';
import { PrivateBoardCopyExistingShareLink } from '~/components/PrivateBoard/PrivateBoardCopyExistingShareLink';
import { PrivateBoardShareButton } from '~/components/PrivateBoard/PrivateBoardShareButton';
import { FollowButton } from '~/components/Shared/HeaderBar/FollowButton';
import { intercomIDs } from '~/constants/intercomIDs';
import { SHARE_BUTTON } from '~/constants/testIDs';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useGetExistingShortIdInformation } from '~/swr-hooks/shortUrl/useGetExistingShortIdInformation';
import { canViewShortUrl } from '~/utils/permissions/shortUrlPermissions';

export const PrivateBoardTopBarActions = memo(() => {
  const boardId = useURLBoardIdSelector();
  const { boardPermissions } = useCurrentBoardPermissions();
  const canViewShareLink = canViewShortUrl(boardPermissions);

  const { data: existingShareLink } = useGetExistingShortIdInformation({
    id: boardId,
    type: ShortUrlObjectType.board,
    boardContext: undefined,
    canView: canViewShareLink,
    refetchOnWindowFocus: false,
  });

  const { isAboveMediumScreen } = useBreakpointsContext();

  if (!isAboveMediumScreen) return null;

  return (
    <div className="flex shrink-0 items-center gap-3">
      <FollowButton />
      <div className="flex">
        <PrivateBoardShareButton
          trigger={
            <Button
              appearance="filled"
              color="grey"
              data-testid={SHARE_BUTTON}
              data-intercomid={intercomIDs.HEADER_ACTIONS_SHARE_BUTTON}
              size="large"
              className={existingShareLink ? 'rounded-r-none' : ''}
            >
              Share
            </Button>
          }
        />
        {!!existingShareLink?.id && (
          <PrivateBoardCopyExistingShareLink
            shareLink={existingShareLink.id}
            data-testid="BOARD_TOP_BAR_COPY_EXISTING_SHARE_LINK"
            appearance="filled"
            size="large"
            color="grey"
            className="rounded-l-none border-l-0"
          />
        )}
      </div>

      <PrivateBoardAddButton />
    </div>
  );
});

PrivateBoardTopBarActions.displayName = 'PrivateBoardTopBarActions';
