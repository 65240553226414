import { Button } from '@air/primitive-button';
import { memo, MouseEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TableCell } from '~/components/TableView/TableCell';
import { TABLE_CELL_V_PADDING, TABLE_ROW_HEIGHT } from '~/components/TableView/ui';
import { PopperOptions, usePopperContainer } from '~/hooks/usePopperContainer';

interface EditableTableCellProps {
  columnWidth: number;
  renderValue?: ((toggleEditor: () => void) => ReactNode) | false;
  renderEditor?: ((toggleEditor: () => void) => ReactNode) | false;
  additionalPopperOffset?: number;
  'data-testid'?: string;
}

const EDITING_CLEARANCE = 100;
export const EditableTableCell = memo(
  ({
    columnWidth,
    renderValue,
    renderEditor,
    additionalPopperOffset = 0,
    ['data-testid']: testId,
  }: EditableTableCellProps) => {
    const tableCellRef = useRef<HTMLDivElement>(null);

    const popperOptions: PopperOptions = useMemo(
      () => ({
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: { offset: [0, -(TABLE_ROW_HEIGHT - TABLE_CELL_V_PADDING * 2) + additionalPopperOffset] },
          },
        ],
      }),
      [additionalPopperOffset],
    );

    const { popperInstance, PopperContainer } = usePopperContainer({
      containerRef: tableCellRef,
      options: popperOptions,
      usePortal: true,
    });

    const [isOpen, setOpen] = useState(false);
    const toggleEditor = useCallback(
      (e?: MouseEvent<HTMLButtonElement>) => {
        // prevents selection of table row when clicking
        e?.stopPropagation();
        if (!renderEditor) {
          return;
        }

        setOpen((v) => !v);
      },
      [renderEditor, setOpen],
    );
    useEffect(() => {
      if (!isOpen && popperInstance.current) {
        popperInstance.current.destroy();
        popperInstance.current = null;
      }
    }, [isOpen, popperInstance]);

    return (
      <TableCell
        ref={tableCellRef}
        width={columnWidth}
        className="group/editable-table-cell"
        // TableRows have a double click behavior that we want to prevent here in
        // order to provide a positive UX when interacting with the editor
        // components
        onDoubleClick={(e) => e.stopPropagation()}
      >
        {renderEditor && isOpen && (
          <PopperContainer className="bg-grey-1" style={{ width: columnWidth + EDITING_CLEARANCE }}>
            {renderEditor(toggleEditor)}
          </PopperContainer>
        )}
        {renderValue ? (
          renderValue(toggleEditor)
        ) : renderEditor ? (
          <Button
            appearance="ghost"
            color="grey"
            className="opacity-0 group-hover/editable-table-cell:opacity-100"
            data-testid={testId}
            onClick={toggleEditor}
            // Prevent item selection when clicking on this button
            onMouseDown={(event) => event.stopPropagation()}
            size="medium"
          >
            Edit
          </Button>
        ) : null}
      </TableCell>
    );
  },
);
EditableTableCell.displayName = 'EditableTableCell';
