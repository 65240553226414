import { ClosedImportModalLocation, useTrackClosedImportModal } from '@air/analytics';
import Router from 'next/router';
import { useCallback } from 'react';

import { ImportOptions } from '~/constants/MenuOptions';
import { Routes } from '~/constants/routes';

interface CloseModalProps {
  location: ClosedImportModalLocation;
}

const LAST_IMPORT_TYPE_KEY = 'lastImportType';

export const useImportModal = () => {
  const { trackClosedImportModal } = useTrackClosedImportModal();

  const openModal = useCallback((activePage?: ImportOptions) => {
    Router.ready(() => {
      let path = Routes.imports.googleDrive;
      if (!!activePage) {
        if (activePage === 'import-from-box') {
          path = Routes.imports.homeBox;
        } else if (activePage === 'import-from-dropbox') {
          path = Routes.imports.dropbox;
        } else if (activePage === 'import-from-brandfolder') {
          path = Routes.imports.brandfolder;
        } else if (activePage === 'import-from-sharePoint') {
          path = Routes.imports.sharePoint;
        }
      }

      const localStorageImportType = localStorage.getItem(LAST_IMPORT_TYPE_KEY);
      const lastImportType = path ? path : localStorageImportType;
      Router.push(`${Router.asPath}${lastImportType}`, undefined, { shallow: true });
    });
  }, []);

  const closeModal = useCallback(
    ({ location }: CloseModalProps) => {
      Router.ready(() => {
        trackClosedImportModal({ location });

        const [basePath, lastImportType] = Router.asPath.split(Routes.import);

        localStorage.setItem(LAST_IMPORT_TYPE_KEY, `${Routes.import}${lastImportType}`);
        Router.push(basePath ?? Routes.media.all, undefined, { shallow: true });
      });
    },
    [trackClosedImportModal],
  );

  return {
    openModal,
    closeModal,
  };
};
