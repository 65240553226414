import { InputProps } from '@air/primitive-input';
import { Spinner } from '@air/primitive-spinner';
import { useCombobox } from 'downshift';
import { ComponentPropsWithoutRef, memo, ReactNode, RefObject, useEffect, useMemo, useRef, useState } from 'react';

import { CreateNewPersonSelectItem } from '~/components/AssetModal/shared/components/FacesPanel/components/PersonTextSelect/components/CreateNewPersonSelectItem';
import {
  PersonSelectItem,
  PersonSelectItemType,
} from '~/components/AssetModal/shared/components/FacesPanel/components/PersonTextSelect/components/PersonSelectItem';
import { PersonSelectItemContainer } from '~/components/AssetModal/shared/components/FacesPanel/components/PersonTextSelect/components/PersonSelectItemContainer';
import { PersonSelectSuggestionsContainer } from '~/components/AssetModal/shared/components/FacesPanel/components/PersonTextSelect/components/PersonSelectSuggestionsContainer';
import { usePersonSuggestionsPopper } from '~/components/AssetModal/shared/components/FacesPanel/components/PersonTextSelect/hooks/usePersonSuggestionsPopper';
import { FacePerson, PersonWithDefaultFace } from '~/swr-hooks/person/types';
import { formatFullName } from '~/utils/formatFullName';

export interface PersonTextSelectProps {
  people: PersonWithDefaultFace[];
  onPersonSelected: (person: PersonWithDefaultFace | undefined) => void;
  onCreateNewPerson?: (personName: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  selectedPerson?: FacePerson;
  isLoading?: boolean;
  onInputChange: (value: string) => void;
  renderInput: (params: {
    inputProps: InputProps;
    comboboxProps: ComponentPropsWithoutRef<'div'>;
    inputRef: RefObject<HTMLInputElement>;
  }) => ReactNode;
  autoFocus?: boolean;
  width?: number;
}

const MAX_DROPDOWN_HEIGHT = 300;
const CREATE_NEW_PERSON = 'CREATE_NEW_PERSON';

const convertPersonToOption = (person: PersonWithDefaultFace): PersonSelectItemType => ({
  value: person.id,
  label: formatFullName(person.firstName, person.lastName) || 'Unnamed',
  thumbnail: person.defaultFace?.thumbnail,
});

export const PersonTextSelect = memo(
  ({
    people,
    onPersonSelected,
    onCreateNewPerson,
    onBlur,
    onFocus,
    selectedPerson,
    isLoading,
    onInputChange,
    renderInput,
    width,
    autoFocus = true,
  }: PersonTextSelectProps) => {
    const popoverRef = useRef(null);
    const personNameRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [inputText, setInputText] = useState('');

    const options = useMemo(() => {
      const convertedPeople = people.map(convertPersonToOption);
      const personTextExists = people.some(
        (person) => formatFullName(person.firstName, person.lastName)?.toLowerCase() === inputText.toLowerCase(),
      );
      if (!!onCreateNewPerson && !!inputText && !personTextExists) {
        convertedPeople.push({ value: CREATE_NEW_PERSON, label: 'Create new person' });
      }

      return convertedPeople;
    }, [inputText, onCreateNewPerson, people]);

    const {
      isOpen,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      closeMenu,
      getItemProps,
      highlightedIndex,
      inputValue,
      openMenu,
    } = useCombobox({
      initialInputValue: selectedPerson ? formatFullName(selectedPerson.firstName, selectedPerson.lastName) : '',
      items: options,
      itemToString: (item): string => {
        if (item?.value === CREATE_NEW_PERSON) {
          return inputValue;
        }
        return item ? item.label : '';
      },
      onIsOpenChange: ({ isOpen }) => {
        if (!isOpen) {
          onBlur?.();
        }
      },
      onInputValueChange: ({ inputValue: newInputValue }) => {
        if (newInputValue !== inputValue) {
          onInputChange(newInputValue ?? '');
        }
      },
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem) {
          if (selectedItem.value === CREATE_NEW_PERSON) {
            onCreateNewPerson?.(inputValue);
          } else {
            const selectedPerson = people.find((person) => person.id === selectedItem.value);
            onPersonSelected(selectedPerson);
          }
        }
        closeMenu();
      },
    });

    useEffect(() => {
      setInputText(inputValue);
    }, [inputValue]);

    // downshift throws a warning/error when it is rendered on portal. Why? No idea.
    const comboboxProps = getComboboxProps({ ref: personNameRef }, { suppressRefError: true });
    const inputProps = getInputProps(
      {
        ref: inputRef,
        onFocus: () => {
          openMenu();
          onFocus?.();
        },
        autoFocus,
      },
      { suppressRefError: true },
    );
    const menuProps = getMenuProps({ ref: popoverRef }, { suppressRefError: true });

    const { popperStyles, popperAttributes } = usePersonSuggestionsPopper({
      personNameRef,
      popoverRef,
      maxDropdownHeight: MAX_DROPDOWN_HEIGHT,
      width,
    });

    const styles = useMemo(
      () => ({
        ...popperStyles,
        width,
      }),
      [popperStyles, width],
    );

    return (
      <div className="flex grow">
        {renderInput({ inputProps, comboboxProps, inputRef })}

        <PersonSelectSuggestionsContainer
          data-testid="PEOPLE_SUGGESTIONS"
          data-theme="light"
          className={!isOpen || (!isLoading && options.length === 0) ? 'invisible' : ''}
          style={styles}
          {...popperAttributes}
          {...menuProps}
        >
          {isOpen && (
            <>
              {isLoading ? (
                <PersonSelectItemContainer>
                  <Spinner className="mr-2 size-4 text-teal-9" />
                  <p>Loading...</p>
                </PersonSelectItemContainer>
              ) : (
                options.map((item, index) => (
                  <div key={index} {...getItemProps({ item, index })}>
                    {item.value === CREATE_NEW_PERSON ? (
                      <CreateNewPersonSelectItem
                        label={`Create "${inputValue}"?`}
                        isHighlighted={highlightedIndex === index}
                      />
                    ) : (
                      <PersonSelectItem
                        isSelected={options[index].value === selectedPerson?.id}
                        person={options[index]}
                        isHighlighted={highlightedIndex === index}
                      />
                    )}
                  </div>
                ))
              )}
            </>
          )}
        </PersonSelectSuggestionsContainer>
      </div>
    );
  },
);

PersonTextSelect.displayName = 'PersonTextSelectProps';
