import { Clip, ClipType } from '@air/api/types';
import React, { memo, useCallback, useState } from 'react';

import { ClipThumbnail } from '~/components/Shared/ClipList/ClipItem/ClipThumbail';
import { PREVIEW_SIZE } from '~/components/TableView/NameCell/NameCellContent';
import { VideoScrubbableThumbnail } from '~/components/VideoScrubbableThumbnail';
import { useVideoScrubbableThumbnail } from '~/hooks/useVideoScrubbableThumbnail';
import { ClipIconSize } from '~/types/clip-icon-size';
import ClipUtils from '~/utils/ClipUtils';

export interface AssetCellThumbnailProps
  extends Pick<Clip, 'status' | 'ext' | 'type' | 'assets' | 'title' | 'importedName' | 'width' | 'height'> {
  description: string;
}

export const AssetCellThumbnail = memo(
  ({ width, height, type, ext, status, assets, description }: AssetCellThumbnailProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const { backgroundImageRef, backgroundImageStyles, indicatorRef, onMouseLeave, onMouseMove } =
      useVideoScrubbableThumbnail({
        containerDimensions: {
          height: PREVIEW_SIZE,
          width: PREVIEW_SIZE,
        },
        frameDimensions: {
          height,
          width,
        },
      });

    const handleMouseEnter = useCallback(() => setIsHovered(true), []);

    const handleMouseLeave = useCallback(() => {
      setIsHovered(false);
      onMouseLeave();
    }, [onMouseLeave]);

    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={onMouseMove}>
        <ClipThumbnail
          ext={ext}
          status={status}
          type={type}
          borderRadius={2}
          description={description}
          src={assets.image}
          iconSize={ClipIconSize.small}
          hideText={true}
          style={{
            padding: 0,
          }}
        />
        {type === ClipType.video && !ClipUtils.isProcessing(status) && !!assets.spriteSheet && isHovered && (
          <VideoScrubbableThumbnail
            backgroundImageRef={backgroundImageRef}
            indicatorRef={indicatorRef}
            src={assets.spriteSheet}
            style={backgroundImageStyles}
          />
        )}
      </div>
    );
  },
);

AssetCellThumbnail.displayName = 'AssetCellThumbnail';
