import { AlertModalDescription } from '@air/primitive-alert-modal';
import Link from 'next/link';
import pluralize from 'pluralize';

import { DeletePrivateClipsResult } from '~/swr-hooks/clips/useDeletePrivateClips';

type DeleteAssetMessageModalDescriptionProps = Pick<DeletePrivateClipsResult, 'ignoredAssetIds' | 'removedAssetIds'>;

export const DeleteAssetMessageModalDescription = ({
  ignoredAssetIds,
  removedAssetIds,
}: DeleteAssetMessageModalDescriptionProps) => {
  return (
    <AlertModalDescription className="pb-2 pt-1">
      <p className="text-14">
        {ignoredAssetIds.length
          ? `You don't have the necessary permissions to delete ${pluralize(
              'this',
              ignoredAssetIds.length,
            )} ${pluralize('asset', ignoredAssetIds.length)} in all of ${pluralize(
              'its',
              ignoredAssetIds.length,
            )} locations.`
          : `Since you don't have the necessary permissions to delete ${pluralize(
              'this',
              removedAssetIds.length,
            )} ${pluralize('asset', removedAssetIds.length)} in all of ${pluralize(
              'its',
              removedAssetIds.length,
            )} locations, ${pluralize('this', removedAssetIds.length)} ${pluralize(
              'asset',
              removedAssetIds.length,
            )} ${pluralize('was', removedAssetIds.length)} removed from board(s) instead.`}
        <Link
          target="_blank"
          className="px-3 text-14 font-bold text-blue-11"
          href="https://help.air.inc/en/articles/8584302-deleting-content"
        >
          Learn more
        </Link>
      </p>
    </AlertModalDescription>
  );
};
