import { useBreakpointsContext } from '@air/provider-media-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo, useRef } from 'react';

import { PrivateBoardHeader } from '~/_app/(private-board)/_components/PrivateBoardHeader';
import { PrivateBoardMobileSearchBar } from '~/_app/(private-board)/_components/PrivateBoardMobileSearchBar';
import { PrivateBoardMobileTopBar } from '~/_app/(private-board)/_components/PrivateBoardMobileTopBar';
import { PrivateBoardStatsBar } from '~/_app/(private-board)/_components/PrivateBoardStatsBar';
import { PrivateBoardTopBar } from '~/_app/(private-board)/_components/PrivateBoardTopBar';
import { PrivateFiltersReduxObserver } from '~/components/Filters/PrivateFiltersReduxObserver';
import { PrivateBoardCustomFieldsPanel } from '~/components/PrivateBoard/components/PrivateBoardCustomFieldsPanel/PrivateBoardCustomFieldsPanel';
import { PrivateBoardView } from '~/components/PrivateBoard/PrivateBoardView';
import { PrivateBoardViewControls } from '~/components/PrivateBoard/PrivateBoardViewControls';
import { PRIVATE_BOARD_PAGE_CONTENT } from '~/constants/testIDs';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { canSeeCustomFields } from '~/utils/permissions/workspacePermissions';

interface PrivateBoardContentProps {
  boardId?: string;
}

export const PrivateBoardContent = memo(({ boardId }: PrivateBoardContentProps) => {
  const { mobileSearch: mobileSearchFlag } = useFlags();
  const { isSearchActive } = useIsSearchActive();
  const { boardPermissions } = useCurrentBoardPermissions();
  const canViewCustomFields = canSeeCustomFields(boardPermissions);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const { isAboveMediumScreen } = useBreakpointsContext();

  return (
    <div
      id="scrollContainer"
      className="flex grow flex-col overflow-y-auto"
      data-testid={PRIVATE_BOARD_PAGE_CONTENT}
      ref={mainRef}
    >
      <PrivateFiltersReduxObserver boardId={boardId} key={boardId} />
      {isSearchActive && !isAboveMediumScreen && mobileSearchFlag && <PrivateBoardMobileSearchBar />}
      {isAboveMediumScreen || !mobileSearchFlag ? (
        <PrivateBoardTopBar ref={headerRef} />
      ) : !isSearchActive ? (
        <PrivateBoardMobileTopBar />
      ) : null}
      {!isSearchActive && <PrivateBoardHeader />}
      <PrivateBoardViewControls headerRef={headerRef} scrollRef={mainRef} />
      <PrivateBoardView scrollElementRef={mainRef} />
      <PrivateBoardStatsBar />
      {canViewCustomFields && <PrivateBoardCustomFieldsPanel />}
    </div>
  );
});

PrivateBoardContent.displayName = 'PrivateBoardContent';
