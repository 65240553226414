import { Skeleton } from '@air/component-skeleton';
import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import { memo, useMemo, useState } from 'react';

import { ImgixImage } from '~/components/ImgixImage';
import { getCloudFrontUrlFromImgixUrl } from '~/constants/cloudfront';
import { ClipFaceThumbnail } from '~/swr-hooks/clips/faces/types';
import { getRoundedImgixDimensions } from '~/utils/getRoundedImgixDimensions';

const facePersonThumbnailContainer = tailwindVariants({
  base: 'flex items-center justify-center',
  variants: {
    shape: {
      square: 'rounded',
      circle: 'rounded-full',
    },
  },
});

const facePersonThumbnailImage = tailwindVariants({
  base: 'h-full w-full rounded object-cover',
  variants: {
    shape: {
      square: 'rounded',
      circle: 'rounded-full',
    },
  },
});

type FacePersonThumbnailShape = VariantProps<typeof facePersonThumbnailContainer>['shape'];

interface FacePersonThumbnailProps {
  faceThumbnail: ClipFaceThumbnail;
  width: number;
  height: number;
  shape?: FacePersonThumbnailShape;
  className?: string;
}

export const FacePersonThumbnail = memo(
  ({ faceThumbnail: { region, image }, width, height, shape = 'square', className }: FacePersonThumbnailProps) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const imgixDimensions = useMemo(() => getRoundedImgixDimensions({ width, height }), [height, width]);

    const { faceHeight, faceTop, faceWidth, faceLeft } = useMemo(() => {
      const totalWidth = Math.round(region.absoluteWidth / region.width);
      const totalHeight = Math.round(region.absoluteHeight / region.height);

      const faceTopWithBuffer = Math.round(region.top * totalHeight - region.absoluteHeight * 0.5);
      const faceLeftWithBuffer = Math.round(region.left * totalWidth - region.absoluteWidth * 0.5);

      const faceTop = Math.max(0, faceTopWithBuffer);
      const faceLeft = Math.max(0, faceLeftWithBuffer);

      // returned region is very small, it contains only the basic face region
      // we want to show a little more for better UX
      let faceWidth = region.absoluteWidth * 2;
      let faceHeight = region.absoluteHeight * 2;

      if (faceTopWithBuffer < 0) {
        faceHeight += faceTopWithBuffer;
      }

      if (faceTop + faceHeight > totalHeight) {
        faceHeight = totalHeight - faceTop;
      }

      if (faceLeftWithBuffer < 0) {
        faceWidth += faceLeftWithBuffer;
      }

      if (faceLeft + faceWidth > totalWidth) {
        faceWidth = totalWidth - faceLeft;
      }

      return {
        faceTop,
        faceLeft,
        faceWidth,
        faceHeight,
      };
    }, [region.absoluteHeight, region.absoluteWidth, region.height, region.left, region.top, region.width]);

    return (
      <div
        className={facePersonThumbnailContainer({ shape, className })}
        style={{ width, height, maxHeight: height, maxWidth: width }}
      >
        {!isLoaded && (
          <Skeleton className={facePersonThumbnailImage({ shape })} variant={shape === 'circle' ? 'round' : 'square'} />
        )}
        <ImgixImage
          draggable={false}
          onLoad={() => setIsLoaded(true)}
          fallbackUrl={getCloudFrontUrlFromImgixUrl(image)}
          alt="New face"
          src={image}
          width={imgixDimensions.width}
          height={imgixDimensions.height}
          disableSrcSet
          className={facePersonThumbnailImage({ shape, className: isLoaded ? '' : 'hidden' })}
          imgixParams={{
            fit: 'crop',
            w: width * 2,
            h: height * 2,
            rect: [faceLeft, faceTop, faceWidth, faceHeight].join(','),
          }}
        />
      </div>
    );
  },
);

FacePersonThumbnail.displayName = 'FacePersonThumbnail';
