import maxSize from 'popper-max-size-modifier';
import { CSSProperties, useMemo } from 'react';
import { usePopper } from 'react-popper';

import { applyMaxSize, sameWidth } from '~/components/Select/popperModifiers';
import { insertIf } from '~/utils/insertIf';

interface PersonSuggestionsPopperProps {
  personNameRef: React.RefObject<HTMLDivElement>;
  popoverRef: React.RefObject<HTMLDivElement>;
  maxDropdownHeight: number;
  width?: number;
}

export const usePersonSuggestionsPopper = ({
  personNameRef,
  popoverRef,
  maxDropdownHeight,
  width,
}: PersonSuggestionsPopperProps) => {
  const { styles, attributes } = usePopper(personNameRef.current, popoverRef.current, {
    placement: 'bottom',
    strategy: 'fixed',
    modifiers: [
      maxSize,
      applyMaxSize,
      ...insertIf(!width, () => [sameWidth]),
      {
        name: 'eventListeners',
        options: {
          scroll: true,
          resize: false,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  // Memoize popper styles so we're no repainting the div they're passed to every re-render.
  const popperStyles = useMemo<CSSProperties>(() => {
    const maxStylesHeight = styles.popper.maxHeight ? parseInt(styles.popper.maxHeight as string) : null;

    return {
      ...styles.popper,
      borderRadius: 4,
      maxHeight: maxStylesHeight ? Math.min(maxStylesHeight, maxDropdownHeight) : maxDropdownHeight,
      zIndex: 2,
      overflow: 'auto',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.25)',
    };
  }, [maxDropdownHeight, styles.popper]);

  return {
    popperStyles,
    popperAttributes: attributes.popper,
  };
};
