import { Board } from '@air/api/types';
import { memo, useCallback } from 'react';

import { ItemProps } from '~/components/Gallery/types';
import {
  EditableTableTitle,
  EditableTableTitleProps,
} from '~/components/TableView/NameCell/NameCellContent/EditableTableTitle';
import { TableAssetTitle } from '~/components/TableView/NameCell/NameCellContent/TableAssetTitle';
import { useUpdateBoard } from '~/swr-hooks/boards/useUpdateBoard';

export interface PrivateBoardNameCellTitleProps {
  isRenaming: boolean;
  setIsRenaming: (isRenaming: boolean) => void;
  onClick: ItemProps['onItemClick'];
  onCmdClick?: ItemProps['onItemCmdClick'];
  board: Board;
}

export const PrivateBoardNameCellTitle = memo(
  ({ isRenaming, onClick, onCmdClick, board, setIsRenaming }: PrivateBoardNameCellTitleProps) => {
    const {
      updateBoard: { mutate: updateBoard },
    } = useUpdateBoard();

    const onRenameComplete = useCallback<EditableTableTitleProps['onRenameComplete']>(
      (title) => {
        updateBoard({ board, trackLocation: 'table-cell', values: { title } });
        setIsRenaming(false);
      },
      [board, setIsRenaming, updateBoard],
    );

    return (
      <div
        className="relative ml-3 w-full"
        style={{
          zIndex: isRenaming ? 9999 : undefined,
        }}
        data-disableselect="true"
        onDoubleClick={(e) => {
          if (isRenaming) e.stopPropagation();
        }}
        onMouseDown={(e) => {
          if (isRenaming) e.stopPropagation();
        }}
        draggable={isRenaming ? true : undefined}
        onDragStart={(e) => {
          if (isRenaming) e.preventDefault();
        }}
      >
        {isRenaming ? (
          <EditableTableTitle title={board.title} setIsRenaming={setIsRenaming} onRenameComplete={onRenameComplete} />
        ) : (
          <TableAssetTitle title={board.title} onClick={onClick} onCmdClick={onCmdClick} />
        )}
      </div>
    );
  },
);

PrivateBoardNameCellTitle.displayName = 'PrivateBoardNameCellTitle';
