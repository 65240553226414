import { memo } from 'react';

import Linkify from '~/components/Linkify';
import { ResizableEditableTextInput } from '~/components/ResizableEditableTextInput';

export interface EditableHeaderDescriptionProps {
  onSubmit?: (newValue: string) => void;
  text: string | undefined;
  testId?: string;
  label: string;
}

export const EditableContentHeaderDescription = memo(
  ({ label, onSubmit, text, testId }: EditableHeaderDescriptionProps) => {
    if (onSubmit) {
      return (
        <div className="-ml-1 inline-flex">
          <ResizableEditableTextInput
            data-testid={testId}
            label={label}
            placeholder="Click to add a description"
            defaultValue={text}
            onSubmit={onSubmit}
            appearance="ghost"
            size="small"
          />
        </div>
      );
    }

    return (
      <Linkify>
        <p
          data-testid={testId}
          data-disableselect="true"
          className="whitespace-pre-wrap break-words text-14 text-grey-12"
        >
          {text}
        </p>
      </Linkify>
    );
  },
);

EditableContentHeaderDescription.displayName = 'EditableHeaderDescription';
