import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import { PeopleFilter, PeopleFilterProps } from '~/components/Filters/PeopleFilter/PeopleFilter';
import {
  clearPeopleFilterValuesAction,
  removeFilterAction,
  setPeopleFiltersValueAction,
} from '~/store/filters/actions';
import { selectedPeopleFiltersSelector } from '~/store/filters/selectors';
import { SelectedFilter } from '~/store/filters/types';
import { PersonWithDefaultFace } from '~/swr-hooks/person/types';

export interface PeopleFilterCardProps extends BaseFilterCardProps {
  PersonSelect: PeopleFilterProps['PeopleSelect'];
}

export const PeopleFilterCard = memo(({ PersonSelect, ...props }: PeopleFilterCardProps) => {
  const dispatch = useDispatch();

  const clearValues = useCallback(() => {
    dispatch(clearPeopleFilterValuesAction());
  }, [dispatch]);

  const selectedPeople = useSelector(selectedPeopleFiltersSelector);

  const onSelectedPeopleChanged = useCallback(
    (person: PersonWithDefaultFace) => dispatch(setPeopleFiltersValueAction({ people: [person] })),
    [dispatch],
  );

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
    clearValues();
  };

  return (
    <FilterCard currentFilter="people" onClearClick={clearValues} onRemoveFilter={onRemoveFilter} {...props}>
      <PeopleFilter
        PeopleSelect={PersonSelect}
        selectedPerson={selectedPeople?.[0]}
        onSelectedPersonChanged={onSelectedPeopleChanged}
      />
    </FilterCard>
  );
});

PeopleFilterCard.displayName = 'PeopleFilterCard';
