import { memo, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  PersonTextSelect,
  PersonTextSelectProps,
} from '~/components/AssetModal/shared/components/FacesPanel/components/PersonTextSelect/PersonTextSelect';
import { hasUnnamedPerson } from '~/swr-hooks/clips/faces/utils';
import { FacePerson, PersonWithDefaultFace } from '~/swr-hooks/person/types';
import { useGetPrivatePeopleList } from '~/swr-hooks/person/useGetPrivatePeopleList';
import { useGetPrivatePerson } from '~/swr-hooks/person/useGetPrivatePerson';

export interface PrivatePersonTextSelectProps {
  onPersonSelected: (selectedPerson: PersonWithDefaultFace | undefined) => void;
  onPersonCreateClick?: (personName: string) => void;
  onBlur?: () => void;
  selectedPerson: FacePerson | undefined;
  renderInput: PersonTextSelectProps['renderInput'];
  autoFocus?: boolean;
  refetchOnFocus?: boolean;
  width?: number;
  additionalPeople?: PersonWithDefaultFace[];
}

export const PrivatePersonTextSelect = memo(
  ({
    onPersonSelected,
    onBlur,
    selectedPerson,
    onPersonCreateClick,
    renderInput,
    autoFocus,
    width,
    refetchOnFocus = true,
    additionalPeople,
  }: PrivatePersonTextSelectProps) => {
    const [search, setSearch] = useState('');
    const {
      data: people = [],
      isFetching,
      refetch,
    } = useGetPrivatePeopleList({
      search,
      limit: 10,
      sortField: 'firstName',
      sortDirection: 'asc',
      peopleType: 'named',
    });

    const { data: currentPerson, isLoading: isLoadingSelectedPerson } = useGetPrivatePerson({
      personId: selectedPerson?.id,
      enabled: !!selectedPerson && !hasUnnamedPerson(selectedPerson),
    });

    const [onSearch] = useDebouncedCallback((value: string) => {
      setSearch(value);
    }, 300);

    const peopleToShow = useMemo<PersonWithDefaultFace[]>(() => {
      if (isFetching || isLoadingSelectedPerson) {
        return [];
      }

      const additionalPeopleIds = additionalPeople?.map((person) => person.id) ?? [];
      const peopleWithoutSelected = people.filter(
        (person) => person.id !== currentPerson?.id && !additionalPeopleIds.includes(person.id),
      );
      const peopleToAdd = additionalPeople?.filter((person) => person.id !== currentPerson?.id) ?? [];

      if (currentPerson && !hasUnnamedPerson(currentPerson)) {
        peopleToAdd.unshift(currentPerson);
      }

      return [...peopleToAdd, ...peopleWithoutSelected].slice(0, 10);
    }, [additionalPeople, currentPerson, isFetching, isLoadingSelectedPerson, people]);

    return (
      <PersonTextSelect
        width={width}
        key={selectedPerson?.id} // reset the component when selectedPerson changes
        autoFocus={autoFocus}
        onInputChange={onSearch}
        isLoading={isFetching || isLoadingSelectedPerson}
        selectedPerson={selectedPerson}
        onBlur={onBlur}
        onFocus={refetchOnFocus ? refetch : undefined}
        people={peopleToShow}
        onPersonSelected={onPersonSelected}
        onCreateNewPerson={onPersonCreateClick ? onPersonCreateClick : undefined}
        renderInput={renderInput}
      />
    );
  },
);

PrivatePersonTextSelect.displayName = 'PrivatePersonTextSelect';
