import { memo, ReactNode } from 'react';

export type BoardHeaderProps = {
  actions?: ReactNode;
  ancestors?: ReactNode;
  customFields?: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
};

export const BoardHeader = memo(({ actions, ancestors, customFields, description, title }: BoardHeaderProps) => {
  return (
    <header className="flex flex-col items-start gap-3 px-3 py-4 md:px-12 md:py-0" data-testid="BOARD_HEADER">
      <div className="flex w-full flex-col items-start gap-1">
        {!!ancestors && (
          <div className="w-full shrink-0" data-disableselect>
            {ancestors}
          </div>
        )}
        {(!!actions || !!title) && (
          <div className="flex min-h-[48px] items-center gap-2">
            {!!title && (
              <div className="flex max-w-[650px] shrink" data-disableselect>
                {title}
              </div>
            )}
            {!!actions && (
              <div className="flex shrink-0 items-center" data-disableselect>
                {actions}
              </div>
            )}
          </div>
        )}
        {!!description && (
          <div className="contents shrink-0" data-disableselect>
            {description}
          </div>
        )}
      </div>
      {!!customFields && (
        <div className="shrink-0" data-disableselect>
          {customFields}
        </div>
      )}
    </header>
  );
});

BoardHeader.displayName = 'BoardHeader';
