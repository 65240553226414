import { useMobileNavigationContext } from '@air/mobile-navigation';
import { Close, Search } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import pluralize from 'pluralize';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { PrivateBoardFiltersButton } from '~/components/Filters/FiltersButton/PrivateBoardFiltersButton';
import { useSearchParams } from '~/hooks/search/useSearchParams';
import { centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { useCanShowBoards } from '~/swr-hooks/gallery/useCanShow';
import { usePrivateSearchTotals } from '~/swr-hooks/search/usePrivateSearchTotals';

export const PrivateBoardMobileSearchBar = memo(() => {
  const { setPanel } = useMobileNavigationContext();
  const { search, clearSearch } = useSearchParams();
  const boardTitle = useSelector(centralizedBoardTitleSelector);
  const { canShowBoards } = useCanShowBoards();
  const { total } = usePrivateSearchTotals({ showBoards: canShowBoards, trackLocation: 'board' });

  return (
    <div>
      <div className="flex h-14 items-center justify-between gap-2 px-3">
        <button className="flex shrink-0 grow items-center gap-2" onClick={() => setPanel('search')}>
          <Search className="size-6 text-grey-10" />
          <span className="text-16 text-grey-12">{search}</span>
        </button>
        <div className="flex items-center gap-2">
          <PrivateBoardFiltersButton title="Filter Board" />
          <div className="h-6 w-px bg-grey-4" />
          <IconButton
            appearance="ghost"
            color="grey"
            icon={Close}
            label="Clear search"
            /**
             * @TODO - In a separate PR, update this to also clear filters the same way as `useFiltersController`.
             */
            onClick={clearSearch}
          />
        </div>
      </div>
      <div className="flex h-10 items-center bg-grey-3 px-3 text-14 text-grey-9">
        <strong className="font-medium text-grey-11">{pluralize('result', total, true)}</strong>&nbsp;in {boardTitle}
      </div>
    </div>
  );
});

PrivateBoardMobileSearchBar.displayName = 'PrivateBoardMobileSearchBar';
