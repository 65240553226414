import { People } from '@air/api';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { isObject } from 'lodash';
import { useMemo } from 'react';
import invariant from 'tiny-invariant';

import { PeopleType } from '~/_app/(people)/utils/types';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { callAirApi } from '~/swr-hooks/callAirApi';
import { PeopleSortField, PrivatePeopleListResponse } from '~/swr-hooks/person/types';

export const PRIVATE_PEOPLE_LIST_KEY = 'PRIVATE_PEOPLE_LIST';

export interface UseGetPrivatePeopleListParams {
  search?: string;
  limit?: number;
  sortField?: PeopleSortField;
  sortDirection?: 'desc' | 'asc';
  peopleType?: PeopleType;
}

type PrivatePeopleListKeyParams = UseGetPrivatePeopleListParams & { workspaceId: string | undefined };

type PrivatePeopleListKey = [typeof PRIVATE_PEOPLE_LIST_KEY, PrivatePeopleListKeyParams];

export const getPrivatePeopleListKey = (params: PrivatePeopleListKeyParams): PrivatePeopleListKey => [
  PRIVATE_PEOPLE_LIST_KEY,
  params,
];

export const isPrivatePeopleListKey = (key: unknown): key is PrivatePeopleListKey => {
  return Array.isArray(key) && key[0] === PRIVATE_PEOPLE_LIST_KEY && isObject(key[1]);
};

export type PrivatePeopleListCacheType = InfiniteData<PrivatePeopleListResponse>;

type PersonSearchParams = NonNullable<Parameters<typeof People.search>[0]>;

export const useGetPrivatePeopleList = ({
  search = '',
  limit = 50,
  sortField = 'firstName',
  sortDirection = 'desc',
  peopleType,
  enabled = true,
}: UseGetPrivatePeopleListParams & { enabled?: boolean }) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const queryKey = getPrivatePeopleListKey({
    workspaceId: currentWorkspace?.id,
    search,
    limit,
    sortField,
    sortDirection,
    peopleType,
  });

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, refetch, isFetching } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }) => {
      invariant(currentWorkspace?.id, 'Workspace ID is required to fetch people');

      const searchParams: PersonSearchParams = {
        workspaceId: currentWorkspace?.id,
        search,
        cursor: pageParam || undefined,
        sortField: { name: sortField, direction: sortDirection },
        limit,
      };

      if (peopleType === 'named') {
        searchParams.filters = { named: { is: true } };
      } else if (peopleType === 'unnamed') {
        searchParams.filters = { named: { is: false } };
      }

      return callAirApi<PrivatePeopleListResponse>(() => People.search(searchParams));
    },
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor || null,
    initialPageParam: '',
    enabled: enabled && !!currentWorkspace?.id,
  });

  const flattenedData = useMemo(() => data?.pages.flatMap((page) => page.data) ?? [], [data?.pages]);

  return {
    data: flattenedData,
    isLoading: isLoading || !currentWorkspace?.id,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    total: data?.pages[0]?.total ?? 0,
    refetch,
  };
};
