import { useTrackKanbanDragCancel, useTrackKanbanDragStart } from '@air/analytics';
import { BaseCustomField, VisibleColumnType } from '@air/api/types';
import { Portal } from '@air/primitive-portal';
import { DragCancelEvent, DragEndEvent, DragOverlay, DragStartEvent, useDndMonitor } from '@dnd-kit/core';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useKanbanContext } from '~/components/KanbanView/Providers/KanbanProvider';
import { reorderKanbanColumnsByIdAction } from '~/store/configViews/actions';
import { kanbanItemDragCancelAction, kanbanItemDragStartAction } from '~/store/kanbanManager/actions';
import {
  DndSortableKanbanColumnData,
  DndSortableKanbanItemData,
  isDndDroppableKanbanColumnData,
  isDndSortableKanbanColumnData,
  isDndSortableKanbanItemData,
} from '~/types/DndKit';

import { DndDragKanbanColumn } from './DndDragKanbanColumn';
import { KanbanItemsDragPreview } from './KanbanItemsDragPreview';
import { snapCornerToCursor } from './snapCornerToCursor';
import { snapToKanbanColumnHandler } from './snapToKanbanColumnHandler';
import { useUpdateKanbanItemPosition } from './useUpdateKanbanItemPosition/useUpdateKanbanItemPosition';

export interface DndKanbanManagerProps {
  baseCustomField: BaseCustomField;
}

/**
 * This manages only the kanban drag and drop items
 * and provides the appropriate DragOverlay for kanban items
 */
export const DndKanbanManager = memo(({ baseCustomField }: DndKanbanManagerProps) => {
  // dnd-kit has an activeItem in its context, but it is faulty and not performant. This is sufficient to render an overlay
  const [draggedItemData, setDraggedItemData] = useState<DndSortableKanbanItemData | null>(null);
  const [draggedColumnData, setDraggedColumnData] = useState<DndSortableKanbanColumnData | null>(null);
  const dispatch = useDispatch();
  const { updateKanbanItemPosition } = useUpdateKanbanItemPosition();
  const { trackKanbanDragCancel } = useTrackKanbanDragCancel();
  const { trackKanbanDragStart } = useTrackKanbanDragStart();
  const { customFieldValues } = useKanbanContext();

  const onDragStart = useCallback(
    ({ active }: DragStartEvent) => {
      const activeData = active.data.current;
      if (isDndSortableKanbanItemData(activeData)) {
        setDraggedItemData(activeData);
        const originColumnName =
          customFieldValues?.find((cfValue) => cfValue.id === activeData.currentKanbanColumnId)?.value ||
          VisibleColumnType.unassignedCustomFieldValue;
        trackKanbanDragStart({
          dragContent: [activeData.itemId],
          originColumnId: activeData.currentKanbanColumnId,
          originColumnName,
        });
        dispatch(kanbanItemDragStartAction());
      } else if (isDndSortableKanbanColumnData(activeData)) {
        setDraggedColumnData(activeData);
      }
    },
    [customFieldValues, dispatch, trackKanbanDragStart],
  );

  const onDragCancel = useCallback(
    (args?: DragCancelEvent) => {
      if (draggedItemData) {
        const originColumnName =
          customFieldValues?.find((cfValue) => cfValue.id === draggedItemData.currentKanbanColumnId)?.value ||
          VisibleColumnType.unassignedCustomFieldValue;
        trackKanbanDragCancel({
          dragContent: [draggedItemData.itemId],
          originColumnId: draggedItemData.currentKanbanColumnId,
          originColumnName,
        });
        setDraggedItemData(null);
        dispatch(kanbanItemDragCancelAction());
      }
      if (draggedColumnData && !args?.active) {
        // check for active is because of arbitrary cancel calls happening when moving cursor too fast,
        // resulting in drag of background item

        setDraggedColumnData(null);
      }
    },
    [customFieldValues, dispatch, draggedItemData, draggedColumnData, trackKanbanDragCancel],
  );

  const onDragEnd = useCallback(
    ({ over }: DragEndEvent) => {
      // Don't use `active` from DragEndEvent because in infinite scroll it can be empty object when
      // dragging from lower to higher in a column
      const overData = over?.data.current;
      if (draggedItemData && customFieldValues) {
        if (isDndDroppableKanbanColumnData(overData) || isDndSortableKanbanItemData(overData)) {
          updateKanbanItemPosition({
            activeData: draggedItemData,
            over,
            customFieldValues,
            baseCustomField,
          });
        } else {
          onDragCancel();
        }
      } else if (
        draggedColumnData &&
        isDndSortableKanbanColumnData(overData) &&
        draggedColumnData.kanbanColumnId !== overData.kanbanColumnId &&
        overData.kanbanColumnId !== VisibleColumnType.unassignedCustomFieldValue
      ) {
        dispatch(
          reorderKanbanColumnsByIdAction({
            activeColumnId: draggedColumnData.kanbanColumnId,
            overColumnId: overData.kanbanColumnId,
          }),
        );
      } else {
        onDragCancel();
      }
      setDraggedColumnData(null);
      setDraggedItemData(null);
    },
    [
      draggedItemData,
      draggedColumnData,
      updateKanbanItemPosition,
      customFieldValues,
      baseCustomField,
      onDragCancel,
      dispatch,
    ],
  );

  useDndMonitor({
    onDragStart,
    onDragCancel,
    onDragEnd,
  });

  const isDraggingColumn = !!draggedColumnData;

  useEffect(() => {
    if (isDraggingColumn) {
      document.body.classList.add('dragging');
    } else {
      document.body.classList.remove('dragging');
    }
  }, [isDraggingColumn]);

  if (draggedItemData)
    return (
      <Portal>
        <DragOverlay dropAnimation={null} modifiers={[snapCornerToCursor]}>
          <KanbanItemsDragPreview itemData={draggedItemData} />
        </DragOverlay>
      </Portal>
    );
  if (draggedColumnData) {
    return (
      <Portal>
        <DragOverlay modifiers={[snapToKanbanColumnHandler]}>
          <DndDragKanbanColumn draggedColumnData={draggedColumnData} />
        </DragOverlay>
      </Portal>
    );
  }

  return null;
});

DndKanbanManager.displayName = 'DndKanbanManager';
