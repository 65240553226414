import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentPropsWithoutRef, memo } from 'react';

interface PersonSelectItemContainerProps extends ComponentPropsWithoutRef<'li'> {
  isHighlighted?: boolean;
}

export const PersonSelectItemContainer = memo(
  ({ isHighlighted, className, ...props }: PersonSelectItemContainerProps) => (
    <li
      className={tailwindMerge(
        'flex cursor-pointer items-center rounded p-1.5 text-14 text-grey-12',
        isHighlighted && 'bg-grey-4',
        className,
      )}
      {...props}
    />
  ),
);

PersonSelectItemContainer.displayName = 'PersonSelectItemContainer';
