import { type Account, type Discussion } from '@air/api/types';
import { useCallback } from 'react';

import { VideoTimestampMarker } from './VideoTimestampMarker';

export type VideoTimestampProps = {
  activeDiscussionId: Discussion['id'] | null;
  duration: number;
  markers?: {
    discussionId: Discussion['id'];
    initials: string;
    timestamp: number;
    userAvatar?: string;
    userId: Account['id'];
  }[];
  onMarkerClick: (timestamp: number) => void;
};

export const VideoTimestamp = ({ activeDiscussionId, duration, markers, onMarkerClick }: VideoTimestampProps) => {
  /**
   * @TODO - change `markers` data type earlier on in `getTimestampMarkers` to avoid double
   *  loops through `markers`
   */
  const getSharedTimestampMarkers = useCallback(
    (timestamp: number) => {
      const sharedMarkers = markers?.filter((marker) => marker.timestamp === timestamp);
      const activeMarker = sharedMarkers?.find((marker) => activeDiscussionId === marker.discussionId);
      const markerToShow = activeMarker || (sharedMarkers && sharedMarkers[0]);
      const count = sharedMarkers?.length;

      return {
        markerToShow,
        count,
        discussionIds: sharedMarkers?.map(({ discussionId }) => discussionId) ?? [],
      };
    },
    [activeDiscussionId, markers],
  );

  return (
    <div className="pointer-events-none absolute inset-0 z-10">
      {markers?.map((marker) => {
        const { markerToShow, count } = getSharedTimestampMarkers(marker.timestamp);

        return (
          <VideoTimestampMarker
            key={marker.discussionId}
            onClick={() => onMarkerClick(marker.timestamp)}
            style={{
              left: `${(marker.timestamp / duration) * 100}%`,
            }}
            userAvatar={markerToShow?.userAvatar}
            userId={markerToShow ? markerToShow.userId : marker.userId}
            userInitials={count && count > 1 ? `+${count}` : markerToShow?.initials || ''}
          />
        );
      })}
    </div>
  );
};
