import { type AirActionTrackingLocation } from '@air/analytics';
import { type Asset } from '@air/api/types';
import { ConfirmationModal } from '@air/component-confirmation-modal';
import { matchesAirror, UNAUTHORIZED_INACTIVE_OBJECT_ACTION } from '@air/errors';
import { useToasts } from '@air/provider-toast';
import { resetSelectedItemsAction } from '@air/redux-selected-items';
import pluralize from 'pluralize';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useDeletePrivateClips } from '~/swr-hooks/clips/useDeletePrivateClips';

import { DeleteAssetMessageModal, DeleteAssetMessageModalProps } from './DeleteAssetMessageModal';

interface DeletePrivateClipsModalProps {
  trackLocation: AirActionTrackingLocation;
  assetIds: Asset['id'][];
  parentBoardId?: string;
  onDelete?: () => void;
}

export const DeletePrivateClipsModal = ({
  assetIds,
  onClose,
  onDelete,
  parentBoardId,
  trackLocation,
}: AirModalProps<DeletePrivateClipsModalProps>) => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const { deletePrivateClips } = useDeletePrivateClips();
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const [deleteAssetModalProps, setDeleteAssetModalProps] = useState<DeleteAssetMessageModalProps | null>(null);
  const { showToast } = useToasts();

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  const deleteClips = async () => {
    try {
      setDeleting(true);
      const deletePrivateClipsResult = await deletePrivateClips({
        assetIds,
        trackLocation,
        parentBoardId,
      });
      setDeleting(false);
      onDelete?.();
      if (deletePrivateClipsResult.ignoredAssetIds.length || deletePrivateClipsResult.removedAssetIds.length) {
        setDeleteAssetModalProps({
          ...deletePrivateClipsResult,
          onClose: () => {
            setDeleteAssetModalProps(null);
            onClose();
          },
        });
      } else {
        dispatch(resetSelectedItemsAction());
        onClose();
      }
    } catch (error) {
      if (matchesAirror(error, UNAUTHORIZED_INACTIVE_OBJECT_ACTION)) {
        showToast(`One or more items were already deleted.`);
      } else {
        showToast(`There was an error. Please try again later.`);
      }

      setDeleting(false);
    }
  };

  if (deleteAssetModalProps) {
    return <DeleteAssetMessageModal {...deleteAssetModalProps} />;
  }

  return (
    <ConfirmationModal
      ctaPrimaryText="Delete"
      description={
        <p className="text-14">
          Deleting {pluralize('this', assetIds.length)} {pluralize('asset', assetIds.length)} will also delete all of
          its versions from all locations for everyone in your workspace.
        </p>
      }
      id="delete-private-clips"
      isDangerous
      isLoading={deleting}
      isOpen
      onConfirm={deleteClips}
      onDismiss={onClose}
      title={`Delete ${pluralize('asset', assetIds.length, true)}?`}
    />
  );
};
