import { InlineInput, InlineInputProps } from '@air/component-inline-input';
import { isError, noop } from 'lodash';
import React, { memo } from 'react';
import { object } from 'yup';

import { BoardTitleValidation } from '~/swr-hooks/boards/useCreateNewBoard';

export interface EditableTableTitleProps {
  title: string;
  setIsRenaming?: (nextIsRenaming: boolean) => void;
  onRenameComplete: Required<InlineInputProps>['onSubmit'];
}

const EDITING_CLEARANCE = 200;

const TitleValidationSchema = object().shape({
  ['title']: BoardTitleValidation,
});

const validateTitle = (value: string) => {
  try {
    TitleValidationSchema.validateSync({ title: value });
  } catch (error) {
    return isError(error) ? error.message : 'Invalid title';
  }
};

export const EditableTableTitle = memo(({ title, setIsRenaming = noop, onRenameComplete }: EditableTableTitleProps) => (
  <InlineInput
    rows={1}
    allowNewLines={false}
    label="Row name"
    autoFocus
    data-testid="TABLE_TITLE_INPUT"
    defaultValue={title}
    onSubmit={onRenameComplete}
    onCancel={() => setIsRenaming(false)}
    validate={validateTitle}
    className="ml-[-5px] w-full"
    inputClassName="px-1 font-medium py-1"
    style={{
      minWidth: EDITING_CLEARANCE,
    }}
  />
));

EditableTableTitle.displayName = 'EditableTableTitle';
