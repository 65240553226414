import { AssetCustomFieldValue, BoardCustomFieldValue } from '@air/api/types';
import React, { memo } from 'react';

import Linkify from '~/components/Linkify';
import { ResizableEditableTextInput } from '~/components/ResizableEditableTextInput';
import { CUSTOM_FIELD_PLAIN_TEXT } from '~/constants/testIDs';

export interface CustomFieldPlainTextProps {
  field: BoardCustomFieldValue | AssetCustomFieldValue;
  onSubmit: ((value: string) => void) | undefined;
}

export const CustomFieldPlainText = memo(({ field, onSubmit }: CustomFieldPlainTextProps) => {
  if (onSubmit) {
    return (
      <ResizableEditableTextInput
        label="Field value"
        placeholder="None"
        data-testid={`${CUSTOM_FIELD_PLAIN_TEXT}-${field.name}`}
        defaultValue={field.plainTextValue}
        onSubmit={onSubmit}
        appearance="outline"
      />
    );
  }
  return (
    <Linkify>
      <div
        data-testid="READONLY_CUSTOM_FIELD_PLAIN_TEXT"
        data-fieldname={field.name}
        className="whitespace-pre-wrap break-normal px-2 py-1 text-14 leading-normal text-grey-11"
      >
        {field.plainTextValue || 'None'}
      </div>
    </Linkify>
  );
});

CustomFieldPlainText.displayName = 'CustomFieldPlainText';
