import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import pluralize from 'pluralize';
import React, { memo, useCallback, useMemo, useState } from 'react';

import { ResizableEditableTextInput } from '~/components/ResizableEditableTextInput';

import { BulkEditCustomFieldModalProps } from './types';

export interface BulkEditPlainTextCustomFieldModalProps extends BulkEditCustomFieldModalProps {
  onSave: (params: {
    assetIds: string[];
    boardIds: string[];
    baseCustomField: BulkEditCustomFieldModalProps['field'];
    nextPlainTextValue: string;
  }) => Promise<void>;
}

export const BulkEditPlainTextCustomFieldModal = memo(
  ({
    field,
    assetIds,
    boardIds,
    initialValues,
    onClose,
    onSave,
  }: AirModalProps<BulkEditPlainTextCustomFieldModalProps>) => {
    const allHaveSameValue = useMemo(() => {
      const baseValue = initialValues[0]?.plainTextValue;
      return initialValues.every((cf) => cf.plainTextValue === baseValue);
    }, [initialValues]);

    const originalValue = allHaveSameValue ? initialValues[0]?.plainTextValue || '' : '';
    const [plainTextValue, setPlainTextValue] = useState<string>(originalValue);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const onSubmit = useCallback(
      (newValue: string) => {
        if (originalValue === newValue) {
          setIsTouched(false);
        } else {
          setPlainTextValue(newValue);
        }
      },
      [originalValue],
    );

    const onConfirm = useCallback(async () => {
      setIsSubmitting(true);
      await onSave({
        assetIds,
        boardIds,
        baseCustomField: field,
        nextPlainTextValue: plainTextValue,
      });
      onClose();
    }, [assetIds, boardIds, field, onClose, onSave, plainTextValue]);

    return (
      <Modal isOpen onDismiss={onClose}>
        <div className="flex flex-col gap-6">
          <header className="flex justify-between gap-4">
            <ModalTitle>Edit {field.name}</ModalTitle>
            <ModalCloseButton className="shrink-0" onClick={onClose} />
          </header>
          <p className="text-16 text-grey-11">
            Enter a value below to apply to the {pluralize('item', assetIds.length + boardIds.length, true)} you’ve
            selected.
          </p>
          <ResizableEditableTextInput
            label="Field value"
            placeholder="None"
            defaultValue={plainTextValue}
            onSubmit={onSubmit}
            appearance="outline"
            onChange={() => setIsTouched(true)}
          />
          <footer className="flex justify-end gap-3">
            <Button appearance="ghost" color="grey" onClick={onClose} size="large">
              Cancel
            </Button>
            <Button
              data-testid="BULK_EDIT_PLAIN_TEXT_CUSTOM_FIELD_MODAL_SAVE"
              isLoading={isSubmitting}
              disabled={!isTouched || isSubmitting}
              appearance="filled"
              color="blue"
              size="large"
              onClick={onConfirm}
            >
              Save
            </Button>
          </footer>
        </div>
      </Modal>
    );
  },
);
BulkEditPlainTextCustomFieldModal.displayName = 'BulkEditPlainTextCustomFieldModal';
