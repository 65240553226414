import { Clip } from '@air/api/types';
import { memo } from 'react';

import FileExtensionPreview from '~/components/FileExtensionPreview';

type AssetGalleryCardProcessingProps = Pick<Clip, 'ext'>;

export const AssetGalleryCardProcessing = memo(({ ext }: AssetGalleryCardProcessingProps) => {
  return (
    <div className="shimmer-background relative flex flex-1 flex-col items-center justify-center bg-pigeon-100">
      <FileExtensionPreview ext={ext} text="Processing" color="gray" size="medium" className="grow justify-center" />
    </div>
  );
});

AssetGalleryCardProcessing.displayName = 'AssetGalleryCardProcessing';
