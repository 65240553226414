import { useTrackClickedSaveCustomField } from '@air/analytics';
import { CustomFields } from '@air/api';
import { useQueryClient } from '@tanstack/react-query';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  EditableCustomFieldTableHeader,
  EditableCustomFieldTableHeaderProps,
} from '~/components/TableView/CustomFieldTableHeader/EditableCustomFieldTableHeader';
import { TableCell } from '~/components/TableView/TableCell';
import { getPrivateBoardCustomFieldsKey } from '~/constants/react-query-keys';
import { TABLE_CUSTOM_FIELD_NAME_EDITABLE_TEXT } from '~/constants/testIDs';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { currentViewTypeNameSelector } from '~/store/configViews/selectors';
import { useGetPrivateConfigViewOptions } from '~/swr-hooks/gallery/viewFields/useGetPrivateConfigViewsOptions';
import { canEditCustomFields } from '~/utils/permissions/workspacePermissions';

import { getTableColumnWidth } from '../types';
import { CustomFieldTableHeaderProps } from './types';

export const PrivateCustomFieldTableHeader = memo(({ customFieldId, customFieldName }: CustomFieldTableHeaderProps) => {
  const boardId = useURLBoardIdSelector();
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const viewType = useSelector(currentViewTypeNameSelector);
  const { refetch: refetchConfigViews } = useGetPrivateConfigViewOptions({ viewType });
  const queryClient = useQueryClient();
  const { trackClickedSaveCustomField } = useTrackClickedSaveCustomField();

  const onSubmit = useCallback<EditableCustomFieldTableHeaderProps['onSubmit']>(
    async (newName) => {
      if (!workspaceId) {
        throw new Error('Workspace ID is not defined');
      }

      if (!newName) {
        return;
      }

      await CustomFields.updateCustomField({ workspaceId, customField: { name: newName, id: customFieldId } });

      refetchConfigViews();

      // In the scenario in which the boards custom fields panel is open
      queryClient.invalidateQueries({ queryKey: getPrivateBoardCustomFieldsKey(boardId) });

      trackClickedSaveCustomField({ location: 'table-header' });
    },
    [boardId, customFieldId, queryClient, refetchConfigViews, trackClickedSaveCustomField, workspaceId],
  );

  return (
    <TableCell width={getTableColumnWidth('customField')} data-testid={customFieldName} data-disableselect="true">
      {canEditCustomFields(workspacePermissions) ? (
        <EditableCustomFieldTableHeader
          onSubmit={onSubmit}
          defaultValue={customFieldName}
          data-testid={TABLE_CUSTOM_FIELD_NAME_EDITABLE_TEXT}
        />
      ) : (
        customFieldName
      )}
    </TableCell>
  );
});
PrivateCustomFieldTableHeader.displayName = 'PrivateCustomFieldTableHeader';
