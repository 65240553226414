import { AirActionTrackingLocation } from '@air/analytics';
import { type Asset, type Board } from '@air/api/types';
import { ConfirmationModal } from '@air/component-confirmation-modal';
import { matchesAirror, UNAUTHORIZED_INACTIVE_OBJECT_ACTION } from '@air/errors';
import { useToasts } from '@air/provider-toast';
import { resetSelectedItemsAction } from '@air/redux-selected-items';
import { noop } from 'lodash';
import pluralize from 'pluralize';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useCreateDeleteBoardTask } from '~/components/DeleteBoardTasks/hooks/useCreateDeleteBoardTask';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useDeletePrivateClips } from '~/swr-hooks/clips/useDeletePrivateClips';

import { DeleteAssetMessageModal, DeleteAssetMessageModalProps } from '../DeleteAssetMessageModal';
import { useIsUploadingToSelectedBoard } from '../DeleteBoards/util';

export interface DeleteAssetsAndBoardsModalProps {
  boardIds: Board['id'][];
  assetIds: Asset['id'][];
  onDelete?: () => void;
  parentBoardId?: string;
  trackLocation: AirActionTrackingLocation;
}

export const DeleteAssetsAndBoardsModal = ({
  assetIds,
  boardIds,
  onClose,
  onDelete = noop,
  parentBoardId,
  trackLocation,
}: AirModalProps<DeleteAssetsAndBoardsModalProps>) => {
  const { showToast } = useToasts();
  const dispatch = useDispatch();
  const [isDeleting, setDeleting] = useState(false);
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const { createDeleteBoardTask } = useCreateDeleteBoardTask();
  const { deletePrivateClips } = useDeletePrivateClips();
  const { isUploadingToBoard } = useIsUploadingToSelectedBoard(boardIds);
  const [deleteAssetModalProps, setDeleteAssetModalProps] = useState<DeleteAssetMessageModalProps | null>(null);

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  const confirm = async () => {
    setDeleting(true);

    try {
      const [deletePrivateClipsResult] = await Promise.all([
        deletePrivateClips({ assetIds, trackLocation, parentBoardId }),
        createDeleteBoardTask({ boardIds, trackLocation }),
      ]);

      setDeleting(false);
      onDelete?.();
      if (deletePrivateClipsResult.ignoredAssetIds.length || deletePrivateClipsResult.removedAssetIds.length) {
        setDeleteAssetModalProps({
          ...deletePrivateClipsResult,
          onClose: () => {
            setDeleteAssetModalProps(null);
            onClose();
          },
        });
      } else {
        dispatch(resetSelectedItemsAction());
        onClose();
      }
    } catch (error) {
      if (matchesAirror(error, UNAUTHORIZED_INACTIVE_OBJECT_ACTION)) {
        showToast(`One or more items were already deleted.`);
      } else {
        showToast(`There was an error. Please try again later.`);
      }

      setDeleting(false);
    }
  };

  if (deleteAssetModalProps) {
    return <DeleteAssetMessageModal {...deleteAssetModalProps} />;
  }

  return (
    <ConfirmationModal
      ctaPrimaryText="Delete"
      id="delete-assets-and-boards"
      isDangerous
      isDisabled={isUploadingToBoard}
      isLoading={isDeleting}
      isOpen
      onConfirm={confirm}
      onDismiss={onClose}
      title="Delete selected boards and assets?"
    >
      {isUploadingToBoard && (
        <div className="mb-4 font-medium text-red-9">
          Items are currently being uploaded to this board. The board cannot be deleted until this upload has completed.
        </div>
      )}

      <div className="text-14 text-grey-10">
        <ul className="mt-2 list-inside list-disc">
          <li className="mb-2 text-14">
            Selected {pluralize('asset', assetIds.length)} and all associated versions will be deleted.
          </li>
          <li className="mb-2 text-14">
            Any sub-boards contained within {pluralize('these', boardIds.length)} {pluralize('board', boardIds.length)}{' '}
            will also be deleted.
          </li>
          <li className="mb-2 text-14">
            Assets that live only in {pluralize('these', boardIds.length)} {pluralize('board', boardIds.length)} will be
            deleted.
          </li>
        </ul>
      </div>
    </ConfirmationModal>
  );
};
