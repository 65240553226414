import { type Clip } from '@air/api/types';
import { forwardRef } from 'react';

export type VideoSeekbarPreviewHlsProps = {
  clip: Pick<Clip, 'height' | 'width'>;
};

export const VideoSeekbarPreviewHls = forwardRef<HTMLVideoElement, VideoSeekbarPreviewHlsProps>(
  ({ clip }, forwardedRef) => (
    <div
      className="relative max-h-[100px] w-[172px] min-w-[172px]"
      style={{
        paddingBottom: `${(clip.height / clip.width) * 100}%`,
      }}
    >
      <video className="absolute inset-0 size-full object-contain" ref={forwardedRef} />
    </div>
  ),
);

VideoSeekbarPreviewHls.displayName = 'VideoSeekbarPreviewHls';
