import { InlineInput, InlineInputProps } from '@air/component-inline-input';
import { ButtonProps } from '@air/primitive-button';
import { tailwindMerge, tailwindVariants, VariantProps } from '@air/tailwind-variants';
import classNames from 'classnames';
import React, { memo, useEffect, useState } from 'react';

import { EditableMultilineTextTriggerButton } from '~/_components/InlineInput/EditableMultilineTextTriggerButton';

export const resizableEditableTextInputButton = tailwindVariants({
  base: 'h-full w-full text-[length:inherit] leading-[inherit]',
  variants: {
    size: {
      small: 'py-1.5',
      medium: 'py-2.5',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

export const resizableEditableInput = tailwindVariants({
  base: 'h-full text-[length:inherit] leading-[inherit] text-grey-11 md:text-[length:inherit] md:leading-[inherit]',
  variants: {
    size: {
      small: 'py-1',
      medium: 'py-2',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

type ResizableEditableTextInputButtonVariants = VariantProps<typeof resizableEditableTextInputButton>;

export type ResizableEditableTextInputProps = Pick<
  InlineInputProps,
  'onSubmit' | 'defaultValue' | 'onChange' | 'label' | 'validate' | 'maxLength' | 'allowNewLines' | 'inputClassName'
> &
  ResizableEditableTextInputButtonVariants &
  Pick<ButtonProps, 'appearance'> & {
    'data-testid'?: string;
    placeholder: string;
    className?: string;
  };

export const ResizableEditableTextInput = memo(
  ({
    onSubmit,
    defaultValue,
    appearance,
    onChange,
    className,
    'data-testid': testId,
    placeholder,
    size = 'medium',
    label,
    validate,
    maxLength,
    allowNewLines,
    inputClassName,
  }: ResizableEditableTextInputProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    return (
      <div
        className={tailwindMerge('relative w-full text-14 md:text-14', isEditing ? 'min-w-[130px]' : '', className)}
        data-testid={testId}
      >
        <EditableMultilineTextTriggerButton
          placeholder={placeholder}
          appearance={appearance}
          onClick={() => setIsEditing(true)}
          style={{
            fontWeight: 'inherit',
          }}
          className={resizableEditableTextInputButton({
            size,
            className: isEditing ? 'invisible' : 'visible',
          })}
          value={value}
        />
        {isEditing && (
          <div className={classNames('absolute inset-0', appearance === 'outline' ? 'pt-0.5' : '-mx-px pt-px')}>
            <InlineInput
              allowNewLines={allowNewLines}
              label={label}
              onSubmit={(value) => {
                setIsEditing(false);
                onSubmit?.(value);
              }}
              onCancel={() => {
                setValue(defaultValue);
                setIsEditing(false);
              }}
              className="h-full"
              onChange={(value) => {
                setValue(value);
                onChange?.(value);
              }}
              style={{
                fontWeight: 'inherit',
                overflow: 'hidden',
              }}
              inputClassName={resizableEditableInput({ size, className: inputClassName })}
              defaultValue={value}
              placeholder={placeholder}
              validate={validate}
              maxLength={maxLength}
            />
          </div>
        )}
      </div>
    );
  },
);

ResizableEditableTextInput.displayName = 'ResizableEditableTextInput';
