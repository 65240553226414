import { PublicClip } from '@air/api';
import { BaseCustomField, Clip, ClipType } from '@air/api/types';
import { Comment, Stack } from '@air/next-icons';
import classNames from 'classnames';
import { memo, useMemo } from 'react';

import { CardAction } from '~/components/CardAction';
import { AssetGalleryCardMetaDetails } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCardMetaDetails';
import { AssetGalleryCardRightActions } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCardRightActions';
import { AssetGalleryCardShortInfo } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCardShortInfo';
import { AssetGalleryCardThumbnail } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCardThumbnail';
import { CustomFieldsDisplay } from '~/components/Gallery/CustomFieldsDisplay';
import { GalleryCard, GalleryCardProps } from '~/components/Gallery/GalleryCard/GalleryCard';
import { GalleryCardCustomFieldsAction } from '~/components/Gallery/GalleryCardCustomFieldsAction';
import { GalleryItemType } from '~/components/Gallery/types';
import { getAssetCustomFields } from '~/components/Gallery/utils';
import { VideoScrubbableThumbnail } from '~/components/VideoScrubbableThumbnail';
import { ASSET_CARD, ASSET_CARD_TITLE } from '~/constants/testIDs';
import { useVideoScrubbableThumbnail } from '~/hooks/useVideoScrubbableThumbnail';
import {
  isTitleAndMetadataVisibleSelector,
  visibleCustomFieldsViewOptionsSelector,
} from '~/store/configViews/selectors';
import { SelectableGalleryAssetItem } from '~/store/selectedItems/types';
import ClipUtils from '~/utils/ClipUtils';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface AssetGalleryCardProps<C>
  extends Pick<
    GalleryCardProps<SelectableGalleryAssetItem>,
    'getButtonClassName' | 'getItemMenuOptions' | 'getSelectionMenuOptions' | 'isSelectable'
  > {
  cardHeight: number;
  cardWidth: number;
  clip: C;
  onAssetClick: () => void;
  onAssetTitleCmdClick?: () => void;
  onCustomFieldsClick: () => void;
  onDiscussionsClick?: () => void;
  onVersionsClick?: () => void;
  showVersionsIndicator: boolean;
  canViewCustomFields: boolean;
  allCustomFields: BaseCustomField[] | undefined;
  shouldShowDiscussions: boolean;
}

const _AssetGalleryCard = <C extends Clip | PublicClip>({
  allCustomFields = [],
  cardHeight,
  cardWidth,
  clip,
  getItemMenuOptions,
  isSelectable = true,
  canViewCustomFields,
  onAssetClick,
  onAssetTitleCmdClick,
  getSelectionMenuOptions,
  onCustomFieldsClick,
  onDiscussionsClick,
  onVersionsClick,
  showVersionsIndicator,
  shouldShowDiscussions,
  getButtonClassName,
}: AssetGalleryCardProps<C>) => {
  const clipDimensions = useMemo(() => {
    const aspectRatio = clip.width / clip.height;

    return {
      width: cardWidth,
      height: cardWidth / aspectRatio,
    };
  }, [cardWidth, clip.height, clip.width]);

  const { backgroundImageRef, backgroundImageStyles, indicatorRef, onMouseLeave, onMouseMove } =
    useVideoScrubbableThumbnail({
      containerDimensions: {
        height: cardHeight,
        width: cardWidth,
      },
      frameDimensions: {
        height: clip.height,
        width: clip.width,
      },
    });
  const isTitleAndMetadataVisible = useAirSelector(isTitleAndMetadataVisibleSelector);
  const visibleCustomFields = useAirSelector(visibleCustomFieldsViewOptionsSelector);

  const customFields = useMemo(
    () => getAssetCustomFields({ allCustomFields, clip, visibleCustomFields }),
    [allCustomFields, clip, visibleCustomFields],
  );

  const shouldShowCustomFields = canViewCustomFields && customFields.length > 0;
  const titleLabel = clip.title || clip.importedName || 'Untitled';

  const popoverCustomFields = useMemo(
    () =>
      clip?.customFields?.filter((customField) => {
        return allCustomFields.some((cf) => cf.id === customField.id);
      }),
    [clip, allCustomFields],
  );

  return (
    <GalleryCard
      testId={ASSET_CARD}
      title={titleLabel}
      getItemMenuOptions={getItemMenuOptions}
      getSelectionMenuOptions={getSelectionMenuOptions}
      item={{
        id: clip.id,
        item: clip,
        type: GalleryItemType.asset,
      }}
      onClick={onAssetClick}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      isSelectable={isSelectable}
      getButtonClassName={getButtonClassName}
    >
      {({ setIsMenuOpen, isHovering, isSelected, isHighlighted, isMenuOpen }) => (
        <>
          <div className="pointer-events-none relative grow">
            {clip.assets.spriteSheet &&
            clip.type === ClipType.video &&
            !ClipUtils.isProcessing(clip.status) &&
            isHovering ? (
              <VideoScrubbableThumbnail
                backgroundImageRef={backgroundImageRef}
                indicatorRef={indicatorRef}
                src={clip.assets.spriteSheet}
                style={backgroundImageStyles}
              />
            ) : (
              <AssetGalleryCardThumbnail
                clip={clip}
                isHovering={isHovering}
                isSelected={isSelected || isHighlighted}
                width={clipDimensions.width}
                height={clipDimensions.height}
              />
            )}
            {!isTitleAndMetadataVisible && (
              <div
                className={classNames(
                  'absolute bottom-0 left-0 right-0 flex h-[100px] flex-col justify-end rounded rounded-t-none bg-gradient-to-b from-black/0 to-black/90 px-2 pb-2 pt-1',
                  isHovering ? 'opacity-100' : 'opacity-0',
                )}
              >
                <AssetGalleryCardMetaDetails
                  hasDarkBackground
                  clip={clip}
                  title={titleLabel}
                  onTitleClick={onAssetClick}
                  onTitleCmdClick={onAssetTitleCmdClick}
                  /**
                   * The width is equal to the card width minus the padding on the left and right
                   */
                  width={cardWidth - 16}
                  titleTestId={ASSET_CARD_TITLE}
                />
              </div>
            )}
            {(isTitleAndMetadataVisible || (!isHovering && !isMenuOpen)) && (
              <div className="absolute bottom-2 right-2 z-10">
                <AssetGalleryCardShortInfo clip={clip} />
              </div>
            )}
            <div className="pointer-events-none absolute left-2 top-2 flex gap-1">
              {!!popoverCustomFields?.length && canViewCustomFields && (
                <GalleryCardCustomFieldsAction customFields={popoverCustomFields} onClick={onCustomFieldsClick} />
              )}
              {showVersionsIndicator && !!onVersionsClick && (
                <CardAction
                  data-testid="CARD_VERSIONS_INDICATOR"
                  icon={<Stack className="size-4" />}
                  onClick={onVersionsClick}
                />
              )}
              {shouldShowDiscussions && !!onDiscussionsClick && !!clip.openCommentCount && (
                <CardAction
                  data-testid="CARD_DISCUSSIONS_INDICATOR"
                  icon={<Comment className="size-4" />}
                  onClick={onDiscussionsClick}
                  text={clip.openCommentCount}
                />
              )}
            </div>
            {isHovering && (
              <div className="pointer-events-none absolute right-2 top-2 flex gap-1">
                <AssetGalleryCardRightActions
                  clip={clip}
                  getItemMenuOptions={getItemMenuOptions}
                  getSelectionMenuOptions={getSelectionMenuOptions}
                  setIsMenuOpen={setIsMenuOpen}
                />
              </div>
            )}
          </div>

          {shouldShowCustomFields || isTitleAndMetadataVisible ? (
            <div className="pointer-events-none relative mx-0.5 mt-1.5 flex flex-col items-start gap-1">
              {isTitleAndMetadataVisible && (
                <AssetGalleryCardMetaDetails
                  clip={clip}
                  title={titleLabel}
                  onTitleClick={onAssetClick}
                  /** Card width minus the left and right padding */
                  width={cardWidth - 4}
                  titleTestId={ASSET_CARD_TITLE}
                />
              )}
              {shouldShowCustomFields && (
                <CustomFieldsDisplay
                  className={classNames('px-1', isTitleAndMetadataVisible ? 'mt-0.5' : undefined)}
                  customFields={customFields}
                  onClick={onCustomFieldsClick}
                />
              )}
            </div>
          ) : null}
        </>
      )}
    </GalleryCard>
  );
};

export const AssetGalleryCard = memo(_AssetGalleryCard) as typeof _AssetGalleryCard;
