import { Breadcrumbs, BreadcrumbsItem } from '@air/component-breadcrumbs';
import { useBreakpointsContext } from '@air/provider-media-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEqual } from 'lodash';
import Router from 'next/router';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AncestorImageContainer } from '~/components/AncestorImageContainer';
import { useGeneralLibraryTitle } from '~/components/LibraryBeta/hooks/useGeneralLibraryTitle';
import { createLibraryRoute, Routes } from '~/constants/routes';
import { BOARD_CRUMBS } from '~/constants/testIDs';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import {
  centralizedBoardAncestorsSelector,
  centralizedBoardLibrary,
  CentralizedBoardSelectors,
  centralizedBoardTitleSelector,
} from '~/store/centralizedBoard/selectors';
import { LocationBoard } from '~/store/router/types';
import { GeneralLibrary } from '~/utils/librariesUtils';

export const PrivateBoardHeaderAncestors = memo(() => {
  const { libraries: librariesFlag } = useFlags();
  const library = useSelector(centralizedBoardLibrary);
  const ancestors = useSelector(centralizedBoardAncestorsSelector, isEqual);
  const title = useSelector(centralizedBoardTitleSelector);
  const id = useSelector(CentralizedBoardSelectors.boardId);
  const { goToBoardPage } = useGoToBoardPage();
  const { generalLibraryTitle } = useGeneralLibraryTitle();
  const { isAboveMediumScreen } = useBreakpointsContext();

  const onBoardClick = useCallback(
    (board: LocationBoard) => {
      goToBoardPage({ board, trackLocation: 'breadcrumb' });
    },
    [goToBoardPage],
  );

  const breadcrumbItems = useMemo(() => {
    const items: BreadcrumbsItem[] = [];

    if (librariesFlag) {
      if (library) {
        items.push({
          id: library.id,
          label: library.title,
          className: 'max-w-[170px]',
          onClick: () => Router.push(createLibraryRoute(library.id)),
          prefix: (
            <AncestorImageContainer backgroundColor={library.color.backgroundHex}>
              <p className="text-14">{library.icon}</p>
            </AncestorImageContainer>
          ),
          type: 'button',
        });
      } else {
        items.push({
          id: 'general',
          label: generalLibraryTitle ?? '',
          className: 'max-w-[170px]',
          prefix: (
            <AncestorImageContainer backgroundColor="var(--colors-grey4)">
              <GeneralLibrary.Icon className="size-4" />
            </AncestorImageContainer>
          ),
          onClick: () => Router.push(Routes.generalLibrary),
          type: 'button',
        });
      }
    }

    ancestors?.map((ancestor) =>
      items.push({
        id: ancestor.id,
        label: ancestor.title,
        className: 'max-w-[170px]',
        onClick: () => onBoardClick({ id: ancestor.id, title: ancestor.title }),
        type: 'button',
      }),
    );

    if (id && title) {
      items.push({
        id,
        className: 'max-w-[350px]',
        label: title,
        type: 'item',
      });
    }

    return items;
  }, [ancestors, generalLibraryTitle, id, librariesFlag, library, onBoardClick, title]);

  if (breadcrumbItems.length < 2) return null;

  return (
    <Breadcrumbs
      visibleItemsAtEnd={2}
      className={librariesFlag ? '-ml-0.5' : '-ml-1'}
      data-testid={BOARD_CRUMBS}
      isCompact={breadcrumbItems.length > 4}
      items={breadcrumbItems}
      size={isAboveMediumScreen ? 'medium' : 'small'}
    />
  );
});

PrivateBoardHeaderAncestors.displayName = 'PrivateBoardHeaderAncestors';
