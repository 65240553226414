'use client';
import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import * as RadixProgress from '@radix-ui/react-progress';

export const videoSeekbarProgress = tailwindVariants({
  base: 'pointer-events-none h-1 w-full overflow-hidden rounded bg-grey-7',
  variants: {
    color: {
      grey: 'text-grey-8',
      teal: 'text-teal-9',
    },
  },
  defaultVariants: {
    color: 'grey',
  },
});

export type VideoSeekbarProgressVariants = VariantProps<typeof videoSeekbarProgress>;

export type VideoSeekbarProgressProps = Pick<RadixProgress.ProgressProps, 'className'> &
  VideoSeekbarProgressVariants & {
    percentage: number;
  };

export const VideoSeekbarProgress = ({ className, color, percentage }: VideoSeekbarProgressProps) => {
  return (
    <RadixProgress.Root className={videoSeekbarProgress({ className, color })}>
      <RadixProgress.Indicator
        className="size-full rounded bg-current"
        style={{ transform: `translateX(-${100 - percentage}%)` }}
      />
    </RadixProgress.Root>
  );
};
