import { Board, BoardCustomFieldValue, CustomFieldTypeName } from '@air/api/types';
import { useCallback } from 'react';

import { PrivateBoardCustomFieldDateInput } from '~/components/PrivateBoard/PrivateBoardCustomFieldDateInput';
import { PrivateBoardCustomFieldSelect } from '~/components/PrivateBoard/PrivateBoardCustomFieldSelect';
import { PrivateBoardCustomFieldPlainTextInputTableCell } from '~/components/RightPanel/CustomFields/PrivateBoardCustomFieldPlainTextInputTableCell';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { usePrivateCustomFieldsContext } from '~/providers/PrivateCustomFieldsProvider';
import { AssignedCustomFieldValue } from '~/swr-hooks/customFields/types';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';

import { BaseCustomFieldTableCell } from './BaseCustomFieldTableCell';
import { BoardCustomFieldTableCellProps } from './types';

export const PrivateBoardCustomFieldTableCell = ({
  board,
  customFieldName,
  customFieldId,
}: BoardCustomFieldTableCellProps<Board>) => {
  const actualCustomFieldValue = board.customFields?.find((customField) => customField.id === customFieldId);
  const assignedCustomFieldValue = actualCustomFieldValue as AssignedCustomFieldValue | undefined;

  const { customFields } = usePrivateCustomFieldsContext();
  const customField = customFields?.find((field) => field.id === customFieldId);
  const { boardPermissions } = useBoardPermissions({
    boardId: board.id,
  });
  const canEditCustomFieldValues = canChangeBoardCustomFields(boardPermissions);

  const renderEditableCustomField = useCallback(
    (closeEditor: () => void) => {
      if (!customField) return null;
      if (!canEditCustomFieldValues) return null;
      const boardCustomFieldValue: BoardCustomFieldValue = actualCustomFieldValue || {
        ...customField,
        boardId: board.id,
        value: null,
        values: null,
      };
      switch (customField.type.title) {
        case CustomFieldTypeName.singleSelect:
        case CustomFieldTypeName.multiSelect: {
          return (
            <PrivateBoardCustomFieldSelect
              autoFocus
              boardId={board.id}
              canEditCustomFields={canEditCustomFieldValues}
              onOptionsClose={closeEditor}
              field={boardCustomFieldValue}
              trackingLocation="table-cell"
            />
          );
        }
        case CustomFieldTypeName.plainText: {
          return (
            <PrivateBoardCustomFieldPlainTextInputTableCell
              boardId={board.id}
              closeEditor={closeEditor}
              field={boardCustomFieldValue}
              trackingLocation="table-cell"
            />
          );
        }
        case CustomFieldTypeName.date: {
          return (
            <PrivateBoardCustomFieldDateInput
              isEditing
              boardId={board.id}
              canEditCustomFields={canEditCustomFieldValues}
              onBlur={closeEditor}
              field={boardCustomFieldValue}
              trackingLocation="table-cell"
            />
          );
        }
      }
    },
    [actualCustomFieldValue, board.id, canEditCustomFieldValues, customField],
  );

  return (
    <BaseCustomFieldTableCell
      customFieldName={customFieldName}
      assignedCustomFieldValue={assignedCustomFieldValue}
      renderEditableCustomField={canEditCustomFieldValues ? renderEditableCustomField : undefined}
    />
  );
};
