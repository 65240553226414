import { People } from '@air/api';
import { matchesAirror, NOT_FOUND } from '@air/errors';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { callAirApi } from '~/swr-hooks/callAirApi';
import { PersonWithDefaultFace } from '~/swr-hooks/person/types';

export const getPrivatePersonKey = ({
  personId,
  workspaceId,
}: {
  workspaceId: string | undefined;
  personId: string | undefined;
}) => ['PRIVATE_PERSON', { workspaceId, personId }];

export const useGetPrivatePerson = ({
  personId,
  enabled = true,
}: {
  personId: string | undefined;
  enabled?: boolean;
}) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const key = getPrivatePersonKey({ personId, workspaceId: currentWorkspace?.id });

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(personId, 'Person ID should be defined');
      invariant(currentWorkspace, 'Current workspace should be defined');

      return callAirApi<PersonWithDefaultFace>(() => People.get({ workspaceId: currentWorkspace.id, personId }));
    },
    enabled: enabled && !!personId && !!currentWorkspace,
    retry: (failureCount, error) => {
      // if person is not found, we don't want to retry
      if (matchesAirror(error, NOT_FOUND)) {
        return false;
      }
      return failureCount < 3;
    },
  });
};
