import { Library } from '@air/api/types';
import { memo, useState } from 'react';

import { SearchToken } from '~/components/SearchBar/SearchToken';
import { SEARCH_LIBRARY_TOKEN } from '~/constants/testIDs';
import { IconType } from '~/types/icons';

interface LibraryTokenProps {
  title: Library['title'];
  Icon: IconType;
  onCloseClick?: () => void;
}

export const LibrarySearchToken = memo(({ title, Icon, onCloseClick }: LibraryTokenProps) => {
  const [isNameVisible, setIsNameVisible] = useState(false);

  return (
    <SearchToken
      testId={SEARCH_LIBRARY_TOKEN}
      onMouseEnter={() => setIsNameVisible(true)}
      onMouseLeave={() => setIsNameVisible(false)}
      onCloseClick={isNameVisible ? onCloseClick : undefined}
      tooltipText=""
      tokenName={isNameVisible ? title : ''}
      icon={<Icon className="my-1 mr-1 size-3 shrink-0 text-grey-11" />}
      color="grey"
    />
  );
});

LibrarySearchToken.displayName = 'LibraryToken';
