import { AirActionTrackingLocation } from '@air/analytics';
import { BoardCustomFieldValue } from '@air/api/types';
import { memo, useCallback } from 'react';

import { CustomFieldPlainTextTableCellInput } from '~/components/TableView/TableRow/CustomFieldTableCell/CustomFieldPlainTextTableCellInput';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export interface PrivateBoardCustomFieldPlainTextInputTableCellProps {
  field: BoardCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  boardId: string;
  closeEditor: () => void;
}

export const PrivateBoardCustomFieldPlainTextInputTableCell = memo(
  ({ field, trackingLocation, boardId, closeEditor }: PrivateBoardCustomFieldPlainTextInputTableCellProps) => {
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace?.id;

    const onSubmit = useCallback(
      async (value: string) => {
        if (!boardId || !workspaceId) return;

        await updateItemsCustomFieldValue({
          boardIds: [boardId],
          baseCustomField: field,
          nextPlainTextValue: value,
          trackingLocation,
          workspaceId,
        });
      },
      [boardId, workspaceId, updateItemsCustomFieldValue, field, trackingLocation],
    );

    return (
      <CustomFieldPlainTextTableCellInput
        onBlur={closeEditor}
        defaultValue={field.plainTextValue}
        onSubmit={onSubmit}
        className="-mt-1"
        fieldName={field.name}
      />
    );
  },
);

PrivateBoardCustomFieldPlainTextInputTableCell.displayName = 'PrivateBoardCustomFieldPlainTextInputTableCell';
