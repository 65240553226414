import { ComponentType, memo } from 'react';

import { PrivatePersonFilterSelectProps } from '~/components/Selects/People/PrivatePersonFilterSelect';
import { PersonWithDefaultFace } from '~/swr-hooks/person/types';

export interface PeopleFilterProps {
  PeopleSelect: ComponentType<Omit<PrivatePersonFilterSelectProps, 'people'>>;
  selectedPerson: PersonWithDefaultFace;
  onSelectedPersonChanged: (person: PersonWithDefaultFace) => void;
}

export const PeopleFilter = memo(({ PeopleSelect, onSelectedPersonChanged, selectedPerson }: PeopleFilterProps) => {
  return (
    <div>
      <PeopleSelect selectedPerson={selectedPerson} onSelectedPersonChanged={onSelectedPersonChanged} />
      <p className="mt-3 text-12 text-grey-11">
        This will filter for assets containing this person and anyone with similar features
      </p>
    </div>
  );
});

PeopleFilter.displayName = 'PeopleFilter';
