import { ComponentProps, memo, type RefObject } from 'react';

export const SCRUBBABLE_THUMBNAIL_INDICATOR_WIDTH = 2;

export type VideoScrubbableThumbnailProps = Pick<ComponentProps<'div'>, 'style'> & {
  backgroundImageRef: RefObject<HTMLDivElement>;
  indicatorRef: RefObject<HTMLDivElement>;
  src: string;
};

export const VideoScrubbableThumbnail = memo(
  ({ backgroundImageRef, indicatorRef, src, style }: VideoScrubbableThumbnailProps) => {
    return (
      <div className="pointer-events-none absolute inset-0 size-full overflow-hidden rounded">
        <div
          className="size-full bg-cover"
          style={{
            backgroundImage: `url("${src}")`,
            ...style,
          }}
          ref={backgroundImageRef}
        />
        <div
          className="pointer-events-none absolute left-0 top-0 hidden h-full bg-blue-9"
          ref={indicatorRef}
          style={{ width: SCRUBBABLE_THUMBNAIL_INDICATOR_WIDTH }}
        />
      </div>
    );
  },
);

VideoScrubbableThumbnail.displayName = 'VideoScrubbableThumbnail';
