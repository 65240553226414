import { type Account } from '@air/api/types';
import { Avatar } from '@air/primitive-avatar';
import classNames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';

export type VideoTimestampMarkerProps = Pick<ComponentPropsWithoutRef<'button'>, 'className' | 'onClick' | 'style'> & {
  userAvatar?: string;
  userInitials: string;
  userId: Account['id'];
};

export const VideoTimestampMarker = ({
  className,
  onClick,
  style,
  userAvatar,
  userId,
  userInitials,
}: VideoTimestampMarkerProps) => {
  return (
    <button
      className={classNames('group/video-timestamp-marker absolute inset-y-0', className)}
      data-testid="VIDEO_TIMESTAMP_MARKER"
      onClick={onClick}
      style={style}
    >
      <Avatar
        className="pointer-events-auto absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-2 cursor-pointer transition-all group-hover/video-timestamp-marker:ring group-hover/video-timestamp-marker:ring-white"
        data-commenter-id={userId}
        data-testid="VIDEO_TIMESTAMP_MARKER_AVATAR"
        size={24}
        src={userAvatar}
        text={userInitials}
        colorSeed={userId}
      />
      <div className="pointer-events-auto h-full w-0.5 bg-white transition-all group-hover/video-timestamp-marker:scale-x-150" />
    </button>
  );
};
