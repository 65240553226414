import { TextareaAutosize, TextareaProps } from '@air/primitive-textarea';
import { tailwindMerge } from '@air/tailwind-variants';
import React, { memo, useCallback, useEffect, useState } from 'react';

interface CustomFieldPlainTextTableCellInputProps {
  className?: string;
  defaultValue?: string;
  onSubmit: (value: string) => void;
  onBlur?: () => void;
  fieldName: string;
}

export const CustomFieldPlainTextTableCellInput = memo(
  ({ defaultValue, onSubmit, onBlur, className, fieldName }: CustomFieldPlainTextTableCellInputProps) => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    const handleKeyDown = useCallback<Required<TextareaProps>['onKeyDown']>(
      (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.stopPropagation();
          event.preventDefault();
          onSubmit(value ?? '');
          onBlur?.();
        }
      },
      [onBlur, onSubmit, value],
    );

    const handleEscOnKeyUp = useCallback<Required<TextareaProps>['onKeyUp']>(
      (event) => {
        if (event.key === 'Escape') {
          event.stopPropagation();
          setValue(defaultValue);
          onBlur?.();
        }
      },
      [defaultValue, onBlur],
    );

    const handleFocus = useCallback<Required<TextareaProps>['onFocus']>((event) => {
      const textareaValue = event.target.value;
      event.target.setSelectionRange(textareaValue.length, textareaValue.length);
    }, []);

    return (
      <TextareaAutosize
        data-testid={`CUSTOM_FIELD_PLAIN_TEXT_TEXTAREA-${fieldName}`}
        autoFocus
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onKeyUp={handleEscOnKeyUp}
        onBlur={() => {
          onSubmit(value ?? '');
          onBlur?.();
        }}
        className={tailwindMerge('h-full resize-none py-2 text-14 font-normal text-grey-11 md:text-14', className)}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        defaultValue={value}
        placeholder="None"
      />
    );
  },
);

CustomFieldPlainTextTableCellInput.displayName = 'CustomFieldPlainTextTableCellInput';
