import { Input } from '@air/primitive-input';
import classNames from 'classnames';
import { memo, useCallback, useMemo, useRef } from 'react';

import { PrivatePersonTextSelect } from '~/components/AssetModal/shared/components/FacesPanel/components/ClipFacesList/components/PrivatePersonTextSelect';
import { FacePersonThumbnail } from '~/components/FacePersonThumbnail/FacePersonThumbnail';
import { hasUnnamedPerson } from '~/swr-hooks/clips/faces/utils';
import { PersonWithDefaultFace } from '~/swr-hooks/person/types';

export interface PrivatePersonFilterSelectProps {
  selectedPerson: PersonWithDefaultFace | undefined;
  onSelectedPersonChanged: (newPeople: PersonWithDefaultFace) => void;
}

export const PrivatePersonFilterSelect = memo(
  ({ selectedPerson, onSelectedPersonChanged }: PrivatePersonFilterSelectProps) => {
    const personSelectedOnMount = useRef(selectedPerson);

    const onPersonSelected = useCallback(
      async (selectedPerson: PersonWithDefaultFace | undefined) => {
        if (selectedPerson) {
          onSelectedPersonChanged(selectedPerson);
        }
      },
      [onSelectedPersonChanged],
    );

    const additionalPeople = useMemo(
      () => (personSelectedOnMount.current ? [personSelectedOnMount.current] : undefined),
      [],
    );

    return (
      <PrivatePersonTextSelect
        additionalPeople={additionalPeople}
        key={selectedPerson?.id}
        autoFocus={false}
        refetchOnFocus={false}
        selectedPerson={selectedPerson ?? undefined}
        onPersonSelected={onPersonSelected}
        renderInput={({ inputProps, comboboxProps }) => (
          <div className="relative mr-2 flex grow" {...comboboxProps}>
            {!!selectedPerson?.defaultFace && (
              <FacePersonThumbnail
                className="absolute left-2 top-1/2 -translate-y-1/2"
                shape="circle"
                faceThumbnail={selectedPerson.defaultFace.thumbnail}
                width={20}
                height={20}
              />
            )}
            <Input
              data-testid="PERSON_FILTER_SELECT"
              className={classNames('w-full', !!selectedPerson?.defaultFace ? 'pl-9' : '')}
              placeholder="Search for person"
              size="large"
              {...inputProps}
              value={selectedPerson && hasUnnamedPerson(selectedPerson) ? 'Unnamed' : inputProps.value}
            />
          </div>
        )}
      />
    );
  },
);

PrivatePersonFilterSelect.displayName = 'PeopleSelect';
