import { InlineInput, InlineInputProps } from '@air/component-inline-input';
import { Button } from '@air/primitive-button';
import { memo, useEffect, useState } from 'react';

export type EditableCustomFieldTableHeaderProps = Pick<Required<InlineInputProps>, 'onSubmit' | 'defaultValue'> & {
  'data-testid'?: string;
};

const validateCustomFieldName = (value: string) => {
  if (!value.trim()) {
    return 'Name is required';
  }
};

export const EditableCustomFieldTableHeader = memo(
  ({ onSubmit, defaultValue, 'data-testid': testId }: EditableCustomFieldTableHeaderProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    return (
      <div className="-mx-3.5 w-full" data-testid={testId}>
        {isEditing ? (
          <InlineInput
            rows={1}
            allowNewLines={false}
            label="Custom field name"
            maxLength={40}
            onSubmit={(value) => {
              setIsEditing(false);
              onSubmit?.(value);
            }}
            onCancel={() => {
              setValue(defaultValue);
              setIsEditing(false);
            }}
            inputClassName="w-full font-semibold text-12 md:text-12 mx-px"
            onChange={setValue}
            defaultValue={value}
            placeholder="Custom field name"
            validate={validateCustomFieldName}
          />
        ) : (
          <Button
            appearance="ghost"
            color="grey"
            className="w-full justify-start text-12 font-semibold"
            onClick={() => setIsEditing(true)}
          >
            <span className="truncate">{value}</span>
          </Button>
        )}
      </div>
    );
  },
);

EditableCustomFieldTableHeader.displayName = 'EditableCustomFieldTableHeader';
