import { useAirModal } from '@air/provider-modal';
import { memo } from 'react';

import { SubscriptionExpiredModal } from '~/components/Modals/SubscriptionExpiredModal';
import { ContentHeaderTitle } from '~/components/PageHeader/ContentHeaderTitle';
import { ResizableEditableTextInput, ResizableEditableTextInputProps } from '~/components/ResizableEditableTextInput';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { canViewSubscription } from '~/utils/permissions/workspacePermissions';

export type EditableContentHeaderTitleProps = Pick<
  ResizableEditableTextInputProps,
  'placeholder' | 'label' | 'data-testid' | 'defaultValue' | 'validate' | 'onSubmit' | 'maxLength'
>;

export const EditableContentHeaderTitle = memo(
  ({
    placeholder,
    maxLength,
    label,
    onSubmit,
    'data-testid': testId,
    validate,
    defaultValue,
  }: EditableContentHeaderTitleProps) => {
    const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
    const { data: subscription } = useSubscriptionContext();
    const canSeeSubscription = canViewSubscription(workspacePermissions);
    const [showSubscriptionExpiredModal] = useAirModal(SubscriptionExpiredModal);
    const hasActiveSubscription = !canSeeSubscription || (subscription && subscription.hasActiveSubscription);

    if (onSubmit && hasActiveSubscription) {
      return (
        <ResizableEditableTextInput
          allowNewLines={false}
          maxLength={maxLength}
          inputClassName="whitespace-pre-wrap"
          className="-ml-1 grow text-24 font-semibold leading-[30px] md:text-24 md:leading-[30px]"
          appearance="ghost"
          size="small"
          label={label}
          placeholder={placeholder}
          data-testid={testId}
          defaultValue={defaultValue}
          validate={validate}
          onSubmit={onSubmit}
        />
      );
    }

    return (
      <ContentHeaderTitle
        data-disableselect="true"
        data-testid={testId}
        onClick={!hasActiveSubscription ? () => showSubscriptionExpiredModal() : undefined}
      >
        {defaultValue}
      </ContentHeaderTitle>
    );
  },
);

EditableContentHeaderTitle.displayName = 'EditableContentHeaderTitle';
