import { AirActionTrackingLocation } from '@air/analytics';
import { AssetCustomFieldValue } from '@air/api/types';
import { memo, useCallback } from 'react';

import { CustomFieldPlainTextTableCellInput } from '~/components/TableView/TableRow/CustomFieldTableCell/CustomFieldPlainTextTableCellInput';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export interface PrivateAssetCustomFieldPlainTextInputTableCellProps {
  field: AssetCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  assetId: string;
  closeEditor: () => void;
}

export const PrivateAssetCustomFieldPlainTextInputTableCell = memo(
  ({ field, trackingLocation, assetId, closeEditor }: PrivateAssetCustomFieldPlainTextInputTableCellProps) => {
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace?.id;

    const onSubmit = useCallback(
      async (value: string) => {
        if (!workspaceId) {
          return;
        }

        await updateItemsCustomFieldValue({
          assetIds: [assetId],
          baseCustomField: field,
          nextPlainTextValue: value,
          trackingLocation,
          workspaceId,
        });
      },
      [workspaceId, updateItemsCustomFieldValue, assetId, field, trackingLocation],
    );

    return (
      <CustomFieldPlainTextTableCellInput
        onBlur={closeEditor}
        defaultValue={field.plainTextValue}
        onSubmit={onSubmit}
        className="-mt-1"
        fieldName={field.name}
      />
    );
  },
);

PrivateAssetCustomFieldPlainTextInputTableCell.displayName = 'PrivateAssetCustomFieldPlainTextInputTableCell';
