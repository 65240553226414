import { ShortUrlObjectType } from '@air/api/types';
import { useBreakpointsContext } from '@air/provider-media-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { PrivateBoardEditCustomFieldsButton } from '~/_app/(private-board)/_components/PrivateBoardEditCustomFieldsButton';
import { PrivateBoardFavoriteButton } from '~/_app/(private-board)/_components/PrivateBoardFavoriteButton';
import { PrivateBoardMobileShareButton } from '~/_app/(private-board)/_components/PrivateBoardMobileShareButton';
import { PrivateBoardMoreOptionsButton } from '~/_app/(private-board)/_components/PrivateBoardMoreOptionsButton';
import { BoardHeader } from '~/_components/BoardHeader';
import { BoardHeaderSkeleton } from '~/_components/BoardHeaderSkeleton';
import { PrivateBoardCopyExistingShareLink } from '~/components/PrivateBoard/PrivateBoardCopyExistingShareLink';
import { PrivateBoardHeaderAncestors } from '~/components/PrivateBoard/PrivateBoardHeaderAncestors';
import { PrivateBoardHeaderCustomFields } from '~/components/PrivateBoard/PrivateBoardHeaderCustomFields';
import { PrivateBoardHeaderDescription } from '~/components/PrivateBoard/PrivateBoardHeaderDescription';
import { PrivateBoardHeaderTitle } from '~/components/PrivateBoard/PrivateBoardHeaderTitle';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { centralizedBoardExistsSelector } from '~/store/centralizedBoard/selectors';
import { useGetExistingShortIdInformation } from '~/swr-hooks/shortUrl/useGetExistingShortIdInformation';
import { canSeeBoardCustomFields } from '~/utils/permissions/boardPermissions';
import { canViewShortUrl } from '~/utils/permissions/shortUrlPermissions';

export const PrivateBoardHeader = memo(() => {
  const boardId = useURLBoardIdSelector();
  const boardExists = useSelector(centralizedBoardExistsSelector);
  const { boardPermissions } = useCurrentBoardPermissions();
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { mobileSearch: mobileSearchFlag } = useFlags();

  const canViewShareLink = canViewShortUrl(boardPermissions);

  const { data: existingShareLink } = useGetExistingShortIdInformation({
    id: boardId,
    type: ShortUrlObjectType.board,
    boardContext: undefined,
    canView: canViewShareLink,
    refetchOnWindowFocus: false,
  });

  if (!boardExists) return <BoardHeaderSkeleton />;

  return (
    <BoardHeader
      ancestors={(isAboveMediumScreen || !mobileSearchFlag) && <PrivateBoardHeaderAncestors />}
      actions={
        <>
          {!mobileSearchFlag || isAboveMediumScreen ? (
            <PrivateBoardFavoriteButton size={isAboveMediumScreen ? 'medium' : 'large'} />
          ) : null}
          {!!existingShareLink?.id && (
            <PrivateBoardCopyExistingShareLink
              data-testid="BOARD_HEADER_COPY_EXISTING_SHARE_LINK"
              size={isAboveMediumScreen ? 'medium' : 'large'}
              appearance="ghost"
              color="grey"
              shareLink={existingShareLink.id}
            />
          )}
          {canSeeBoardCustomFields(boardPermissions) && (
            <PrivateBoardEditCustomFieldsButton size={isAboveMediumScreen ? 'medium' : 'large'} />
          )}
          {!isAboveMediumScreen && <PrivateBoardMobileShareButton />}
          <PrivateBoardMoreOptionsButton size={isAboveMediumScreen ? 'medium' : 'large'} />
        </>
      }
      customFields={canSeeBoardCustomFields(boardPermissions) && <PrivateBoardHeaderCustomFields />}
      description={<PrivateBoardHeaderDescription />}
      title={<PrivateBoardHeaderTitle />}
    />
  );
});

PrivateBoardHeader.displayName = 'PrivateBoardHeader';
