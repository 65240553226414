import { memo } from 'react';

import { ImgixImage } from '~/components/ImgixImage';
import { getCloudFrontUrlFromImgixUrl } from '~/constants/cloudfront';

export interface BoardCellThumbnailProps {
  thumbnail: string | undefined;
  title: string;
}

export const BoardCellThumbnail = memo(({ title, thumbnail }: BoardCellThumbnailProps) => {
  const fallbackUrl = thumbnail && getCloudFrontUrlFromImgixUrl(thumbnail);

  return fallbackUrl ? (
    <ImgixImage
      fallbackUrl={fallbackUrl}
      sizes={'40px'}
      alt={title}
      src={thumbnail}
      imgixParams={{
        w: 100,
        h: 100,
        auto: 'compress',
        fit: 'clip',
        cs: 'origin',
      }}
      disableSrcSet
    />
  ) : (
    <div className="size-full bg-pigeon-100" />
  );
});

BoardCellThumbnail.displayName = 'BoardCellThumbnail';
