import { Copy as CopyIcon } from '@air/next-icons';
import { IconButton, IconButtonProps } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { memo } from 'react';

import { useCopyTextToClipboard } from '~/hooks/useCopyTextToClipboard';
import { getFullShareUrl } from '~/utils/shareUrl';

type PrivateBoardCopyExistingShareLinkProps = Omit<IconButtonProps, 'onClick' | 'icon' | 'label'> & {
  shareLink: string;
};

export const PrivateBoardCopyExistingShareLink = memo(
  ({ shareLink, ...props }: PrivateBoardCopyExistingShareLinkProps) => {
    const { copyTextToClipboard } = useCopyTextToClipboard();

    return (
      <Tooltip label="Copy share link">
        <IconButton
          icon={CopyIcon}
          label="Copy share link"
          data-testid="COPY_EXISTING_SHARE_LINK"
          onClick={() => {
            const fullShareUrl = getFullShareUrl(shareLink);
            copyTextToClipboard({
              text: fullShareUrl,
              successMessage: 'Share link copied!',
            });
          }}
          {...props}
        />
      </Tooltip>
    );
  },
);

PrivateBoardCopyExistingShareLink.displayName = 'PrivateBoardCopyExistingShareLink';
