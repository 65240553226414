import { useActionBar } from '@air/provider-action-bar';
import { useToasts } from '@air/provider-toast';
import { memo, useEffect, useRef } from 'react';

import { useGetPrivateAssetMenuOptionsCallback } from '~/components/PrivateGallery/useGetPrivateAssetMenuOptionsCallback';
import { useGetPrivateBoardMenuOptionsCallback } from '~/components/PrivateGallery/useGetPrivateBoardMenuOptionsCallback';
import { BoardsAndAssetsSelectionBar } from '~/components/SelectionActionBar/BoardsAndAssetsSelectionBar';
import { usePrivateSelectionMenuOptions } from '~/hooks/usePrivateSelectionMenuOptions';

const quickActions = ['edit-tags'];

export const PrivateBoardsAndAssetsSelectionBar = memo(() => {
  const { getAssetMenuOptionsCallback } = useGetPrivateAssetMenuOptionsCallback();
  const { getSelectionMenuOptions } = usePrivateSelectionMenuOptions();
  const { getBoardMenuOptionsCallback } = useGetPrivateBoardMenuOptionsCallback();
  const { actionBar } = useActionBar();
  const { setToastBottomOffset } = useToasts();

  const actionbarOffset = useRef(0);

  useEffect(() => {
    const offsetToSet = actionBar ? 72 : 0;
    if (actionbarOffset.current !== offsetToSet) {
      setToastBottomOffset(offsetToSet);
      actionbarOffset.current = offsetToSet;
    }
  }, [actionBar, setToastBottomOffset]);

  return (
    <BoardsAndAssetsSelectionBar
      getBoardMenuOptions={getBoardMenuOptionsCallback}
      getFileMenuOptions={getAssetMenuOptionsCallback}
      getAssetMenuOptions={getAssetMenuOptionsCallback}
      getSelectionMenuOptions={getSelectionMenuOptions}
      quickActionsIds={quickActions}
    />
  );
});

PrivateBoardsAndAssetsSelectionBar.displayName = 'PrivateBoardsAndAssetsSelectionBar';
