import {
  AirActionTrackingLocation,
  useTrackClickedAddCustomField,
  useTrackClickedSaveCustomField,
} from '@air/analytics';
import { Plus as PlusIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { useAirModal } from '@air/provider-modal';
import { memo } from 'react';

import { ADD_CUSTOM_FIELD_MINI_BUTTON } from '~/constants/testIDs';

import { CustomFieldsCreateModal } from './CustomFieldsCreateModal';

interface AddCustomFieldButtonProps {
  trackingLocation: AirActionTrackingLocation;
  onCreate: () => void;
}

export const AddCustomFieldButton = memo(({ trackingLocation, onCreate }: AddCustomFieldButtonProps) => {
  const [showCreateModal] = useAirModal(CustomFieldsCreateModal);
  const { trackClickedSaveCustomField } = useTrackClickedSaveCustomField();
  const { trackClickedAddCustomField } = useTrackClickedAddCustomField();

  const onCustomFieldCreate = async () => {
    await onCreate();
    trackClickedSaveCustomField({ location: trackingLocation });
  };

  const onClick = () => {
    showCreateModal({
      onCreate: onCustomFieldCreate,
    });
    trackClickedAddCustomField({ location: trackingLocation });
  };
  return (
    <Button
      appearance="ghost"
      color="grey"
      data-testid={ADD_CUSTOM_FIELD_MINI_BUTTON}
      onClick={onClick}
      prefix={<PlusIcon className="size-4" />}
      size="medium"
    >
      Add Field
    </Button>
  );
});

AddCustomFieldButton.displayName = 'AddCustomFieldButton';
