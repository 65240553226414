import { useTrackClickedAddContent } from '@air/analytics';
import { Plus } from '@air/next-icons';
import { Button, ButtonProps } from '@air/primitive-button';
import { DropdownMenu, DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { forwardRef, memo } from 'react';

import FreeOutOfStorageModal from '~/components/Modals/FreeOutOfStorageModal';
import { SubscriptionExpiredModal } from '~/components/Modals/SubscriptionExpiredModal';
import { intercomIDs } from '~/constants/intercomIDs';
import { NEW_ACTION_BUTTON } from '~/constants/testIDs';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { useStorage } from '~/swr-hooks/subscriptions/useStorage';
import { canViewSubscription } from '~/utils/permissions/workspacePermissions';

const AddButtonComponent = forwardRef<HTMLButtonElement, Pick<ButtonProps, 'onClick'>>(
  ({ onClick, ...restOfProps }, forwardedRef) => {
    return (
      <Button
        appearance="filled"
        color="blue"
        data-testid={NEW_ACTION_BUTTON}
        data-intercomid={intercomIDs.HEADER_ACTIONS_NEW_BUTTON}
        onClick={onClick}
        prefix={<Plus className="size-4" />}
        ref={forwardedRef}
        size="large"
        {...restOfProps}
      >
        Add
      </Button>
    );
  },
);

AddButtonComponent.displayName = 'AddButtonComponent';

export type AddButtonProps = {
  options: DropdownMenuOption[];
};

export const AddButton = memo(({ options }: AddButtonProps) => {
  const { data: subscription } = useSubscriptionContext();
  const { data: storage } = useStorage();
  const { trackClickedAddContent } = useTrackClickedAddContent();
  const [showSubscriptionExpiredModal] = useAirModal(SubscriptionExpiredModal);
  const [showFreeOutOfStorageModal] = useAirModal(FreeOutOfStorageModal);

  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canSeeSubscription = canViewSubscription(permissions);

  if (canSeeSubscription && !subscription) {
    return null;
  }

  const hasActiveSubscription = !canSeeSubscription || (subscription && subscription.hasActiveSubscription);
  const isOutOfStorage =
    canSeeSubscription && (!subscription || (subscription.isInFreePlan && storage?.isOutOfStorage));

  if (!hasActiveSubscription || isOutOfStorage) {
    return (
      <AddButtonComponent
        onClick={() => {
          trackClickedAddContent({ location: 'header-bar-add-button' });
          if (!hasActiveSubscription) {
            showSubscriptionExpiredModal();
          } else {
            showFreeOutOfStorageModal();
          }
        }}
      />
    );
  }

  return (
    <DropdownMenu
      onOpenChange={(isExpanded: boolean) => {
        if (isExpanded) trackClickedAddContent({ location: 'header-bar-add-button' });
      }}
      className="max-w-[230px]"
      trigger={<AddButtonComponent />}
      align="end"
      size="small"
    >
      {renderDropdownItems({ options })}
    </DropdownMenu>
  );
});

AddButton.displayName = 'AddButton';
