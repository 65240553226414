import { useTrackConnectedSlackIntegration, useTrackViewedBoard } from '@air/analytics';
import { matchesAirror, NO_WORKSPACE_PERMISSION } from '@air/errors';
import { useToasts } from '@air/provider-toast';
import { useSwitchSysadminWorkspace } from '@air/sysadmin/src/useSwitchSysadminWorkspace';
import { once } from 'lodash';
import Router, { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import { useSlackIntregrationProcessLocation } from '~/components/EditPrivateBoardModal/EditBoardModal/SlackIntegration/hooks/useSlackIntregrationProcessLocation';
import { getLayout as getHomeLayout } from '~/components/Layouts/HomeLayout/HomeLayout';
import { PrivateBoardContent } from '~/components/PrivateBoard/PrivateBoardContent';
import { Routes } from '~/constants/routes';
import { QueryParamNames } from '~/constants/search';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useHandleIfNoWorkspacePermissionError } from '~/hooks/useHandleIfNoWorkspacePermissionError';
import { usePrivateBoardPage } from '~/hooks/usePrivateBoardPage';
import { useSwitchWorkspace } from '~/hooks/useSwitchWorkspace';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useURLClipIdSelector } from '~/hooks/useURLClipIdSelector';
import { GetImmutableFilters } from '~/providers/FiltersProvider';
import { resetCentralizedBoardAction, setCentralizedBoardAction } from '~/store/centralizedBoard/actions';
import { centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { resetConfigViewsAction, setSavedConfigurableViewsAction } from '~/store/configViews/actions';
import { currentViewTypeNameSelector } from '~/store/configViews/selectors';
import { NO_CURRENT_USER_ERROR } from '~/swr-hooks/account/useAccount';
import { useBoardSlackIntegrations } from '~/swr-hooks/slack/useBoardSlackIntegrations';
import { addBoardTitleToURL } from '~/utils/BoardUtils';
import { getBoardIdFromPath, getPathWithoutQuery, pushWithExistingQuery } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

export default function PrivateBoardPage({ children }: { children?: ReactNode }) {
  const dispatch = useDispatch();
  const { isReady, push, query } = useRouter();
  const boardId = useURLBoardIdSelector();
  const clipId = useURLClipIdSelector();
  const boardTitle = useSelector(centralizedBoardTitleSelector);
  const { data: board, error } = usePrivateBoardPage(boardId);
  const { trackViewedBoard } = useTrackViewedBoard();
  const { trackConnectedSlackIntegration } = useTrackConnectedSlackIntegration();
  const { slackAuthStatus } = query;
  const { showToast } = useToasts();
  const { data } = useBoardSlackIntegrations(board?.id);
  const { slackIntegrationLocation, removeSlackIntegrationLocation } = useSlackIntregrationProcessLocation();
  const { isInternal, switchSysadminWorkspace } = useSwitchSysadminWorkspace();

  useFetchObjectsPermissions({
    objects: {
      boardIds: boardId ? [boardId] : [],
    },
  });

  useHandleIfNoWorkspacePermissionError(error);

  useEffect(() => {
    if (!boardId && isReady) {
      push(Routes.media.all);
    }
  }, [boardId, isReady, push]);

  useEffect(() => {
    if (error && isReady && !matchesAirror(error, NO_WORKSPACE_PERMISSION) && error.message !== NO_CURRENT_USER_ERROR) {
      push(Routes.media.all);
    }
  }, [boardId, error, isReady, push]);

  useEffect(() => {
    if (slackIntegrationLocation && data?.length && boardTitle) {
      trackConnectedSlackIntegration({
        boardTitle,
        trackLocation: slackIntegrationLocation,
        channelName: data[0].channelName,
      });
      removeSlackIntegrationLocation();
    }
  }, [
    boardId,
    data,
    trackConnectedSlackIntegration,
    boardTitle,
    slackIntegrationLocation,
    removeSlackIntegrationLocation,
  ]);

  useEffect(() => {
    const listener = once(() => {
      if (slackAuthStatus) {
        /**
         * Without the setTimeout, the toast gets fired immediately on mount and it's not obvious the user it popped up.
         * So we wait a second, and then show the toast.
         */
        setTimeout(() => {
          if (slackAuthStatus === 'success') {
            showToast('Slack Authentication succeeded!');
          } else if (slackAuthStatus === 'alreadyExists') {
            showToast('A different Slack Workspace is already connected!');
          } else if (slackAuthStatus === 'failure') {
            showToast('Slack Authentication failed!');
          }
        }, 1000);

        /**
         * 5 seconds later, we clear the slackAuthStatus query param from the URL
         */
        setTimeout(
          () =>
            pushWithExistingQuery({
              path: getPathWithoutQuery(Router.asPath),
              newQuery: { [QueryParamNames.slackAuthStatus]: null },
            }),
          5000,
        );
      }
    });

    /**
     * When the component mounts and slackAuthStatus has a value, we want to fire a toast
     * just once explaining the status. We want to ensure though that the routeChangeComplete
     * event has fired so the ToastProvider doesn't dimiss the toast as soon as it's visible
     * since the provider listens to routeChangeComplete as well.
     */
    Router.events.on('routeChangeComplete', listener);

    return () => Router.events.off('routeChangeComplete', listener);
  }, [showToast, slackAuthStatus]);

  useEffect(() => {
    if (board?.id && !clipId) {
      trackViewedBoard({
        board_id: board.id,
        board_title: board.title,
        isDemo: board.isDemo,
        board_view: board.views?.find(({ isDefault }) => !!isDefault)?.viewType.name ?? undefined,
      });
    }
    // we only want to fire this event when the user has navigated to a new board
    // we only watch the id because if we had board as a dep,
    // if the user updated the board title, it would trigger this hook to fire
    // we watch clipId because we don't want to consider it a board view if they're
    // just looking at the asset modal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board?.id, clipId, trackViewedBoard]);

  const { switchWorkspace } = useSwitchWorkspace();
  const store = useAirStore();

  useEffect(() => {
    dispatch(resetConfigViewsAction());
  }, [boardId, dispatch]);

  useEffect(() => {
    if (board) {
      if (isInternal) {
        switchSysadminWorkspace({ workspaceId: board.workspaceId });
      } else if (board.workspaceId) {
        switchWorkspace({ id: board.workspaceId });
      }

      dispatch(setCentralizedBoardAction({ board }));
      addBoardTitleToURL(board);
    }
  }, [board, dispatch, isInternal, switchSysadminWorkspace, switchWorkspace]);

  useEffect(() => {
    const currentViewTypeName = currentViewTypeNameSelector(store.getState());
    if (board?.views) {
      const expectedViewTypeName = board.views.find((view) => view.isDefault)?.viewType.name;
      if (!currentViewTypeName || currentViewTypeName !== expectedViewTypeName) {
        dispatch(
          setSavedConfigurableViewsAction({
            savedConfigurableViews: board.views,
          }),
        );
      }
    }
  }, [dispatch, board?.views, store]);

  useUnmount(() => {
    dispatch(resetCentralizedBoardAction());
    dispatch(resetConfigViewsAction());
  });

  return (
    <>
      <PrivateBoardContent boardId={boardId} />
      {children}
    </>
  );
}

const getImmutableFilters: GetImmutableFilters = ({ asPath }) => ({
  boardId: getBoardIdFromPath(asPath),
});

export const getLayout = (page: ReactNode) =>
  getHomeLayout(<PrivateBoardPage>{page}</PrivateBoardPage>, getImmutableFilters);

PrivateBoardPage.getLayout = (page: ReactNode) => getHomeLayout(page, getImmutableFilters);
