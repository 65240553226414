import { forwardRef, useMemo } from 'react';

const MAX_HEIGHT = 120;

export type VideoSeekbarPreviewSpritesheetProps = {
  columns: number;
  frameCount: number;
  height: number;
  position: number;
  rows: number;
  src: string;
  width: number;
};

export const VideoSeekbarPreviewSpritesheet = forwardRef(
  ({ columns, height, frameCount, position, rows, src, width }: VideoSeekbarPreviewSpritesheetProps) => {
    const aspectRatio = useMemo(() => {
      const originalAspectRatio = width / height;
      const scaledWidth = Math.round(MAX_HEIGHT * originalAspectRatio);

      return {
        height: MAX_HEIGHT,
        width: scaledWidth,
      };
    }, [height, width]);

    const backgroundSize = useMemo(() => {
      const scaledWidth = aspectRatio.width * columns;
      const scaledHeight = aspectRatio.height * rows;

      return `${scaledWidth}px ${scaledHeight}px`;
    }, [aspectRatio.height, aspectRatio.width, columns, rows]);

    const backgroundPosition = useMemo(() => {
      const currentFrame = Math.floor(position * frameCount);
      const currentRow = Math.min(rows - 1, Math.floor(currentFrame / (frameCount / rows)));
      const isLastFrame = currentFrame === frameCount;
      const currentColumn = isLastFrame ? columns - 1 : currentFrame % columns;

      return `-${currentColumn * aspectRatio.width}px -${currentRow * aspectRatio.height}px`;
    }, [aspectRatio.height, aspectRatio.width, columns, frameCount, position, rows]);

    return (
      <div
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize,
          backgroundPosition,
          ...aspectRatio,
        }}
      />
    );
  },
);

VideoSeekbarPreviewSpritesheet.displayName = 'VideoSeekbarPreviewSpritesheet';
