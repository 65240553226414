import { useTrackClickedInviteGuests, useTrackClickedShareALink } from '@air/analytics';
import { MemberAdd } from '@air/next-icons';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';

import { INVITE_PEOPLE_MENU_OPTION } from '~/constants/testIDs';
import { getDropdownMenuOption } from '~/utils/menuOptions/getDropdownMenuOption';

interface UseGetShareMenuOptionsParams {
  onAddMember: (() => void) | undefined;
  onShareLink: (() => void) | undefined;
}

export const useGetBoardShareMenuOptions = ({
  onAddMember,
  onShareLink,
}: UseGetShareMenuOptionsParams): DropdownMenuOption[] => {
  const { trackClickedShareALink } = useTrackClickedShareALink();
  const { trackClickedInviteGuests } = useTrackClickedInviteGuests();

  const options: DropdownMenuOption[] = [];

  if (onShareLink) {
    options.push({
      ...getDropdownMenuOption('share'),
      description: 'Generate a share link for public or private use',
      onSelect: () => {
        trackClickedShareALink();
        onShareLink();
      },
      type: 'item',
    });
  }

  if (onAddMember) {
    options.push({
      description: 'Invite guests to collaborate on this board via email',
      id: 'invite-guests',
      label: <span data-testid={INVITE_PEOPLE_MENU_OPTION}>Invite guests</span>,
      onSelect: () => {
        trackClickedInviteGuests();
        onAddMember?.();
      },
      prefix: <MemberAdd className="size-4" />,
      type: 'item',
    });
  }

  return options;
};
