import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps, forwardRef, memo } from 'react';

interface PersonSelectDropdownProps extends ComponentProps<'ul'> {
  className?: string;
}

const _PersonSelectSuggestionsContainer = forwardRef<HTMLUListElement, PersonSelectDropdownProps>(
  ({ className, ...props }: PersonSelectDropdownProps, forwardedRef) => {
    return (
      <ul
        ref={forwardedRef}
        className={tailwindMerge(
          'scrollbar-thumb-rounded-full scrollbar-track-rounded-full absolute z-10 flex flex-col gap-1 overflow-y-auto rounded border-none bg-grey-1 px-1.5 py-2 shadow-popover scrollbar scrollbar-track-transparent scrollbar-thumb-grey-7 hover:scrollbar-track-grey-4',
          className,
        )}
        data-select-person-options={true}
        {...props}
      />
    );
  },
);

_PersonSelectSuggestionsContainer.displayName = '_PersonSelectSuggestionsContainer';

export const PersonSelectSuggestionsContainer = memo(_PersonSelectSuggestionsContainer);
PersonSelectSuggestionsContainer.displayName = 'PersonSelectSuggestionsContainer';
