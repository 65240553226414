import { type Clip } from '@air/api/types';
import classNames from 'classnames';
import { type ComponentProps, type MouseEvent, type ReactNode, useCallback, useRef, useState } from 'react';

import {
  VIDEO_SPRITESHEET_COLUMN_COUNT,
  VIDEO_SPRITESHEET_FRAME_COUNT,
  VIDEO_SPRITESHEET_ROW_COUNT,
} from '../constants/spritesheet';
import { useVideoHlsPreview } from '../hooks/useVideoHlsPreview';
import { useVideoPreview } from '../hooks/useVideoPreview';
import { VideoSeekbarPreview } from './VideoSeekbarPreview';
import { VideoSeekbarPreviewHls } from './VideoSeekbarPreviewHls';
import { VideoSeekbarPreviewSpritesheet } from './VideoSeekbarPreviewSpritesheet';
import { VideoSeekbarProgress } from './VideoSeekbarProgress';

export type VideoSeekbar = Pick<ComponentProps<'div'>, 'className'> & {
  clip: Pick<Clip, 'assets' | 'duration' | 'height' | 'id' | 'type' | 'width'>;
  duration: number;
  onSeek: (value: number) => void;
  onSeekEnd: (value: number) => void;
  onSeekStart: () => void;
  processedPercentage: number;
  progressPercentage: number;
  renderTimestamp?: () => ReactNode;
};

export const VideoSeekbar = ({
  className,
  clip,
  duration,
  onSeek,
  onSeekEnd,
  onSeekStart,
  processedPercentage,
  progressPercentage,
  renderTimestamp,
}: VideoSeekbar) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [seekPosition, setSeekPosition] = useState(0);
  const [position, setPosition] = useState(0);
  const { onResetPreview, onUpdatePreview, onUpdatePreviewTimestamp, videoPreviewRef } = useVideoPreview();
  const { onUpdateVideo, videoRef } = useVideoHlsPreview({ clip });

  const onPreviewStart = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (!inputRef.current || (!clip.assets.spriteSheet && !videoRef)) return null;

      const x = event.clientX - inputRef.current.getBoundingClientRect().left;
      const position = x / inputRef.current.offsetWidth;

      onUpdateVideo({ duration: duration || clip.duration || 0, position });
      setSeekPosition(position);
      onUpdatePreview({ x });
      onUpdatePreviewTimestamp({ duration: duration || clip.duration || 0, position });
      setPosition(position);
    },
    [
      clip.assets.spriteSheet,
      clip.duration,
      duration,
      onUpdatePreview,
      onUpdatePreviewTimestamp,
      onUpdateVideo,
      videoRef,
    ],
  );

  const onPreviewEnd = useCallback(() => {
    setSeekPosition(0);
    onResetPreview();
  }, [onResetPreview]);

  return (
    <div
      className={classNames('relative grow select-none', className)}
      onMouseEnter={onPreviewStart}
      onMouseMove={onPreviewStart}
      onMouseLeave={onPreviewEnd}
      style={{
        WebkitTouchCallout: 'none',
      }}
    >
      <VideoSeekbarPreview ref={videoPreviewRef}>
        {clip.assets.spriteSheet ? (
          <VideoSeekbarPreviewSpritesheet
            columns={VIDEO_SPRITESHEET_COLUMN_COUNT}
            height={clip.height}
            position={position}
            src={clip.assets.spriteSheet}
            frameCount={VIDEO_SPRITESHEET_FRAME_COUNT}
            rows={VIDEO_SPRITESHEET_ROW_COUNT}
            width={clip.width}
          />
        ) : (
          <VideoSeekbarPreviewHls clip={clip} ref={videoRef} />
        )}
      </VideoSeekbarPreview>
      <input
        className="absolute bottom-0 h-6 w-full cursor-pointer appearance-none bg-transparent [&::-webkit-slider-thumb]:appearance-none"
        max={1}
        min={0}
        onChange={() => onSeek(seekPosition)}
        onMouseDown={onSeekStart}
        onMouseUp={() => onSeekEnd(seekPosition)}
        onTouchStart={onSeekStart}
        onTouchEnd={() => onSeekEnd(seekPosition)}
        ref={inputRef}
        step="any"
        type="range"
        value={progressPercentage}
      />
      <VideoSeekbarProgress color="teal" percentage={progressPercentage * 100} />
      <VideoSeekbarProgress
        className="absolute top-1/2 -translate-y-1/2 bg-transparent"
        color="grey"
        percentage={processedPercentage * 100}
      />
      {renderTimestamp?.()}
    </div>
  );
};
